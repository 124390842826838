import { useState, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import ConfirmDelete from "../../ConfirmDelete";
import LOBCardView from "./LOBCardView";
import LOBModal from "./LOBModal";
import LOBTableView from "./LOBTableView";
import SearchSort from "../../SearchSort";
import ViewSwitcher from "../../ViewSwitcher";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { Tooltip } from "react-tooltip";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

const libraries = ["places"];

const sortOptions = [
  { value: "address", label: "Address" },
  { value: "city", label: "City" },
  { value: "zip", label: "Zip" },
  { value: "county", label: "County" },
];

export default function LOB() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [editingRow, setEditingRow] = useState(null);
  const [editedLOB, setEditedLOB] = useState({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [open, setOpen] = useState(false);
  const [listView, setListView] = useState(() => {
    const savedViewType = localStorage.getItem("preferredViewType");
    return savedViewType ? savedViewType === "list" : true;
  });
  const {
    ref: sortRef,
    isOpen: showSortOptions,
    setIsOpen: setShowSortOptions,
  } = useOutsideClick(false);
  const {
    ref: ellipsisRef,
    isOpen: showEllipsisDropdown,
    setIsOpen: setShowEllipsisDropdown,
  } = useOutsideClick(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    Axios.CancelToken.source()
  );

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  useEffect(() => {
    if (searchTerm !== null) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    return () =>
      cancelTokenSource.cancel("Component unmounted and request is cancelled");
  }, [cancelTokenSource]);

  const fetchLOB = async () => {
    cancelTokenSource.cancel("Operation canceled due to new request.");

    const newCancelTokenSource = Axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    setIsSearching(!!searchTerm);
    setIsSorting(!!sortField);

    let url = `policy/LineOfBusiness?count=true&top=${itemsPerPage}&skip=${
      (currentPage - 1) * itemsPerPage
    }`;

    // if (searchTerm) {
    //   url += `&$filter=startswith(addressData/addressOne, '${searchTerm}') or startswith(addressData/city, '${searchTerm}') or startswith(addressData/zip, '${searchTerm}') or startswith(addressData/county, '${searchTerm}')`;
    // }

    if (sortField) {
      const oDataSortField =
        sortField === "address"
          ? "addressData/addressOne"
          : `addressData/${sortField}`;
      url += `&$orderby=${oDataSortField} ${sortDirection}`;
    }

    try {
      const response = await Axios.get(url, {
        cancelToken: newCancelTokenSource.token,
      });
      setIsSearching(false);
      setIsSorting(false);

      if (response.data) {
        setTotalItems(response.headers["count"]);
        return response.data;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      setIsSearching(false);
      setIsSorting(false);
      if (Axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching LOB:", error);
      }
      return [];
    }
  };

  const { data: lob, isLoading: lobLoading } = useQuery(
    ["lob", currentPage, itemsPerPage, searchTerm, sortField, sortDirection],
    fetchLOB,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleSortChange = field => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
    setCurrentPage(1);
  };

  const handleChangePage = async newPage => {
    setIsPaginating(true);
    setCurrentPage(newPage);
    await fetchLOB();
    setIsPaginating(false);
  };

  const handleItemsPerPageChange = event => {
    const newValue = event.target.value;
    setItemsPerPage(Number(newValue));
    setCurrentPage(1);
  };

  const handleSetListView = viewType => {
    localStorage.setItem("preferredViewType", viewType ? "list" : "grid");
    setListView(viewType);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {showConfirmDelete && (
        <ConfirmDelete
          open={showConfirmDelete}
          setOpen={setShowConfirmDelete}
        />
      )}
      <LOBModal open={open} setOpen={setOpen} isLoaded={isLoaded} />
      <Tooltip id="actions-tooltip" />
      <div className="flex max-md:flex-col-reverse items-center gap-5 text-zinc-900 dark:text-zinc-100 pb-2">
        <SearchSort
          isSearching={isSearching}
          isSorting={isSorting}
          sortOptions={sortOptions}
          setSearchTerm={setSearchTerm}
          handleSortChange={handleSortChange}
          showSortOptions={showSortOptions}
          setShowSortOptions={setShowSortOptions}
          sortRef={sortRef}
        />
        <div className="flex max-md:justify-between items-center gap-2 max-md:w-full">
          <div className="flex items-center gap-2">
            <button
              className="px-4 py-2 text-sm font-semibold rounded-lg text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800"
              onClick={() => setOpen(!open)}
            >
              Add
            </button>
            <ViewSwitcher listView={listView} setListView={handleSetListView} />
          </div>
          <div ref={ellipsisRef} className="relative h-full">
            <button
              className="flex items-center"
              onClick={() => setShowEllipsisDropdown(!showEllipsisDropdown)}
            >
              <EllipsisVerticalIcon className="h-6 w-6" />
            </button>
            {showEllipsisDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-40 rounded-md shadow-lg bg-white dark:bg-zinc-900 ring-1 ring-black ring-opacity-5 divide-y divide-zinc-100 focus:outline-none">
                <ul className="py-1">
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    AIM Import
                  </li>
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    Upload Excel/CSV
                  </li>
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    Geocode All
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {listView ? (
        <LOBTableView
          currentPage={currentPage}
          editedLOB={editedLOB}
          editingRow={editingRow}
          handleItemsPerPageChange={handleItemsPerPageChange}
          handleChangePage={handleChangePage}
          handleSortChange={handleSortChange}
          isPaginating={isPaginating}
          isSearching={isSearching}
          isSorting={isSorting}
          itemsPerPage={itemsPerPage}
          lob={lob}
          lobLoading={lobLoading}
          setEditedLOB={setEditedLOB}
          setEditingRow={setEditingRow}
          setShowConfirmDelete={setShowConfirmDelete}
          sortDirection={sortDirection}
          sortField={sortField}
          totalItems={totalItems}
        />
      ) : (
        <LOBCardView
          currentPage={currentPage}
          editedLOB={editedLOB}
          editingRow={editingRow}
          handleItemsPerPageChange={handleItemsPerPageChange}
          handleChangePage={handleChangePage}
          handleSortChange={handleSortChange}
          isPaginating={isPaginating}
          isSearching={isSearching}
          isSorting={isSorting}
          itemsPerPage={itemsPerPage}
          lob={lob}
          lobLoading={lobLoading}
          setEditedLOB={setEditedLOB}
          setEditingRow={setEditingRow}
          setShowConfirmDelete={setShowConfirmDelete}
          sortDirection={sortDirection}
          sortField={sortField}
          totalItems={totalItems}
        />
      )}
    </div>
  );
}

import { ListBulletIcon, Squares2X2Icon } from "@heroicons/react/24/outline";

export default function ListGridView({ isList, setIsList }) {
  return (
    <div className="flex items-center">
      <button
        onClick={() => setIsList(true)}
        className={
          isList
            ? "ring-1 ring-inset ring-zinc-900 dark:ring-zinc-100 rounded dark:bg-zinc-900 px-2 py-1"
            : "bg-transparent px-2 py-1"
        }
      >
        <ListBulletIcon className="w-5 h-5" />
      </button>
      <button
        onClick={() => setIsList(false)}
        className={
          !isList
            ? "ring-1 ring-inset ring-zinc-900 dark:ring-zinc-100 rounded dark:bg-zinc-900 px-2 py-1"
            : "bg-transparent px-2 py-1"
        }
      >
        <Squares2X2Icon className="w-5 h-5" />
      </button>
    </div>
  );
}

import { useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import Loader from "../../Loader";
import usePageDesign from "hooks/usePageDesign";

export default function Policy() {
  const { pathname } = useLocation();
  const lookupKey = pathname.split("/").pop();
  const { containerData, inputFieldsLoading, componentMapping } =
    usePageDesign(lookupKey);

  if (inputFieldsLoading) return <Loader />;

  const renderInput = (field) => {
    const fieldType = field.fieldType ? field.fieldType.toLowerCase() : "text";
    const InputComponent = componentMapping[fieldType] || componentMapping.text;

    return (
      <li key={field.id} className="flex flex-col">
        <InputComponent
          label={field.name}
          defaultValue={field.name}
          required={field.required || true}
          listData={field.listData || []}
          id={`input-${field.id}`}
        />
      </li>
    );
  };

  return (
    <div className="w-full h-full text-zinc-900 dark:text-zinc-100 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col gap-y-4 mt-5">
        {containerData.map((container, index) => (
          <div
            key={index}
            className="w-full border border-zinc-300 dark:border-none rounded"
          >
            <Disclosure
              as="div"
              className="divide-y divide-zinc-300 dark:divide-zinc-800"
              defaultOpen
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex justify-between w-full py-3.5 font-semibold text-left text-zinc-900 dark:text-zinc-100 focus:outline-none px-4 bg-white dark:bg-zinc-800 ${
                      open ? "rounded-t" : "rounded"
                    }`}
                  >
                    <span>{container.container}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-zinc-900 dark:text-zinc-100`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-sm text-zinc-500 rounded-b px-4 py-6 bg-white dark:bg-zinc-900">
                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 xl:gap-x-8 mb-2">
                      {container.fields.map(renderInput)}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </div>
  );
}

import { useState, useMemo, useEffect, Suspense } from "react";
import Pagination from "../../Pagination";
import ProgramsCardView from "./ProgramsCardView";
import ProgramsTableView from "./ProgramsTableView";
import SearchSort from "components/SearchSort";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { LuPlus, LuAlignJustify, LuGrid } from "react-icons/lu";
import AddProgramModal from "./AddProgramModal";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import ConfirmDelete from "components/ConfirmDelete";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statuses = {
  Active: "text-green-700 bg-green-100 ring-green-600/20",
  Inactive: "text-red-700 bg-red-100 ring-red-600/10",
};

export default function Programs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [cardView, setCardView] = useState(() => {
    const savedViewType = localStorage.getItem("preferredViewType");
    return savedViewType === "card";
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [newProgramModalOpen, setNewProgramModalOpen] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);

  const fetchPrograms = async ({ queryKey }) => {
    const [, currentPage, itemsPerPage, searchTerm] = queryKey;

    let url = `policy/program?count=true&top=${itemsPerPage}&skip=${
      (currentPage - 1) * itemsPerPage
    }&$filter=isDeleted eq false`;

    if (searchTerm) {
      url += ` and (contains(name, '${searchTerm}') or contains(description, '${searchTerm}'))`;
    }

    try {
      setIsLoading(true);
      const response = await Axios.get(url);
      if (response.data) {
        const totalCount = parseInt(response.headers["count"], 10);
        setTotalItems(totalCount);
        return response.data;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      console.error("Error fetching programs: ", error);
      setTotalItems(0);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const { data: programList = [], refetch } = useQuery(
    ["program", currentPage, itemsPerPage, searchTerm],
    fetchPrograms,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setIsLoading(false);
      },
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, itemsPerPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const displayedPrograms = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return programList.slice(start, start + itemsPerPage);
  }, [currentPage, itemsPerPage, programList]);

  const handleAdd = () => {
    setNewProgramModalOpen(true);
  };

  const handleDeletePrompt = (showPrompt, id) => {
    setShowConfirmDelete(showPrompt);
    setSelectedProgramId(id);
  };

  const handleDelete = async () => {
    try {
      const response = await Axios.delete(
        `policy/program/${selectedProgramId}`
      );
      console.log("Delete response:", response.data);
      refetch();
      toast.success("Program successfully deleted");
    } catch (err) {
      console.log("Delete error response:", err);
      toast.success("Error deleting program");
    }
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        {newProgramModalOpen && (
          <AddProgramModal
            open={newProgramModalOpen}
            setOpen={setNewProgramModalOpen}
            refreshData={refetch}
          />
        )}
      </Suspense>
      {showConfirmDelete && (
        <ConfirmDelete
          open={showConfirmDelete}
          setOpen={setShowConfirmDelete}
          onConfirm={handleDelete}
        />
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="text-zinc-900 dark:text-zinc-100 mb-5">
          <h2 className="text-2xl font-semibold">Programs</h2>
          <p className="text-sm">Create and manage insurance programs</p>
        </div>
        <div className="flex gap-5 text-zinc-900 dark:text-zinc-100">
          <SearchSort
            isSearching={false}
            isSorting={false}
            sortOptions={[
              { value: "name", label: "Name" },
              { value: "description", label: "Description" },
              { value: "carriers", label: "Carriers" },
              { value: "forms", label: "Forms" },
            ]}
            setSearchTerm={setSearchTerm}
            showSortOptions={false}
            setShowSortOptions={() => {}}
            sortRef={null}
            showSort={false}
          />
          <button
            className="flex items-center gap-2 px-4 py-2 text-sm font-semibold rounded-lg dark:text-zinc-300 text-white bg-primary hover:bg-primary-light dark:bg-zinc-800 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800"
            onClick={() => handleAdd()}
          >
            <LuPlus className="h-4 w-4" /> Add New Program
          </button>
          <div className="isolate inline-flex rounded-md shadow-sm">
            <button
              onClick={() => {
                setCardView(true);
                localStorage.setItem("preferredViewType", "card");
              }}
              className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold bg-white dark:bg-zinc-800 text-zinc-600 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 focus:z-10 ${
                cardView === true ? "" : "dark:ring-zinc-600"
              }`}
            >
              <LuAlignJustify className="h-5 w-5" />
            </button>
            <button
              onClick={() => {
                setCardView(false);
                localStorage.setItem("preferredViewType", "list");
              }}
              className={`relative inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold bg-white dark:bg-zinc-800 text-zinc-600 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 focus:z-10 ${
                cardView === true ? "dark:ring-zinc-600" : ""
              }`}
            >
              <LuGrid className="h-5 w-5" />
            </button>
          </div>
        </div>
        {cardView ? (
          <ProgramsTableView
            classNames={classNames}
            displayedPrograms={displayedPrograms}
            statuses={statuses}
            setShowConfirmDelete={handleDeletePrompt}
          />
        ) : (
          <ProgramsCardView
            classNames={classNames}
            displayedPrograms={displayedPrograms}
            statuses={statuses}
          />
        )}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={e => setItemsPerPage(Number(e.target.value))}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}

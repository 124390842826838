import { useState, useEffect } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import ClipLoader from "react-spinners/ClipLoader";
import TaskGrid from "./TaskGridView";
import TaskList from "./TaskListView";

const statuses = {
  Complete: "text-white bg-green-700 ring-green-700/20",
  "In Progress": "text-zinc-900 bg-gray-50 ring-gray-500/10",
  Todo: "text-zinc-900 bg-gray-50 ring-gray-500/10",
  Archived: "text-white bg-yellow-500 ring-yellow-500/20",
};

export default function Tasks({ format }) {
  const [isList, setIsList] = useState(() => {
    const savedPreference = localStorage.getItem("viewPreference");
    return savedPreference === "list";
  });

  useEffect(() => {
    localStorage.setItem("viewPreference", isList ? "list" : "grid");
  }, [isList]);

  const { data: tasks, isLoading: tasksLoading } = useQuery(["task"], () => {
    return Axios.get("https://insurtechies.dev/api/task/task").then(
      (res) => res.data
    );
  });

  if (tasksLoading) {
    return (
      <div
        className={`flex justify-center items-center h-screen w-screen ${
          localStorage.theme === "dark" ? "bg-zinc-800" : "bg-white"
        }`}
      >
        <ClipLoader
          color="green"
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <div>
      {isList ? (
        <TaskList
          isList={isList}
          setIsList={setIsList}
          format={format}
          statuses={statuses}
          tasks={tasks}
        />
      ) : (
        <TaskGrid
          isList={isList}
          setIsList={setIsList}
          format={format}
          statuses={statuses}
          tasks={tasks}
        />
      )}
    </div>
  );
}

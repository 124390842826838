import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ConfirmDelete from "../../../../ConfirmDelete";
import CarrierCardView from "./CarrierCardView";
import CarrierTableView from "./CarrierTableView";
import SearchSort from "components/SearchSort";
import useOutsideClick from "hooks/useOutsideClick";
import { LuPlus } from "react-icons/lu";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { Tooltip } from "react-tooltip";

const sortOptions = [
  { value: "address", label: "Address" },
  { value: "city", label: "City" },
  { value: "zip", label: "Zip" },
  { value: "county", label: "County" },
];

export default function Carrier() {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const {
    ref: sortRef,
    isOpen: showSortOptions,
    setIsOpen: setShowSortOptions,
  } = useOutsideClick(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    Axios.CancelToken.source()
  );
  const pageParams = useParams();
  useEffect(() => {
    if (searchTerm !== null) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    return () =>
      cancelTokenSource.cancel("Component unmounted and request is cancelled");
  }, [cancelTokenSource]);

  const fetchCarriers = async () => {
    cancelTokenSource.cancel("Operation canceled due to new request.");

    const newCancelTokenSource = Axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    setIsSearching(!!searchTerm);
    setIsSorting(!!sortField);

    let url = `policy/carriercontract?count=true&top=${itemsPerPage}&skip=${
      (currentPage - 1) * itemsPerPage
    }&$filter=programId eq ${pageParams.id} and isDeleted eq false`;

    if (searchTerm) {
      url += ` and (contains(name, '${searchTerm}') or contains(description, '${searchTerm}'))`;
    }

    if (sortField) {
      url += `&$orderby=${sortField} ${sortDirection}`;
    }

    try {
      const response = await Axios.get(url, {
        cancelToken: newCancelTokenSource.token,
      });
      setIsSearching(false);
      setIsSorting(false);

      if (response.data) {
        setTotalItems(response.headers["count"]);
        const filteredData = response.data.filter(item => !item.isDeleted);
        return filteredData;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      setIsSearching(false);
      setIsSorting(false);
      if (Axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching carrier:", error);
      }
      return [];
    }
  };

  const { data: carriers, isLoading: carriersLoading } = useQuery(
    [
      "Carrier",
      currentPage,
      itemsPerPage,
      searchTerm,
      sortField,
      sortDirection,
    ],
    fetchCarriers,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleSortChange = field => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
    setCurrentPage(1);
  };

  const handleChangePage = async newPage => {
    setIsPaginating(true);
    setCurrentPage(newPage);
    await fetchCarriers();
    setIsPaginating(false);
  };

  const handleItemsPerPageChange = event => {
    const newValue = event.target.value;
    setItemsPerPage(Number(newValue));
    setCurrentPage(1);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5">
      {showConfirmDelete && (
        <ConfirmDelete
          open={showConfirmDelete}
          setOpen={setShowConfirmDelete}
        />
      )}
      <Tooltip id="actions-tooltip" />
      <div className="flex items-center gap-5 text-zinc-900 dark:text-zinc-100 pb-2">
        <SearchSort
          isSearching={isSearching}
          isSorting={isSorting}
          sortOptions={sortOptions}
          setSearchTerm={setSearchTerm}
          handleSortChange={handleSortChange}
          showSortOptions={showSortOptions}
          setShowSortOptions={setShowSortOptions}
          sortRef={sortRef}
        />
        <div className="flex items-center gap-2">
          <button className="flex items-center gap-2 px-4 py-2 text-sm font-semibold rounded-lg text-white bg-primary hover:bg-primary-light dark:text-zinc-300 dark:bg-zinc-800 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800">
            <LuPlus className="my-auto h-4 w-4" /> Add New Carrier
          </button>
        </div>
      </div>
      <div className="lg:hidden">
        <CarrierCardView
          currentPage={currentPage}
          handleItemsPerPageChange={handleItemsPerPageChange}
          handleChangePage={handleChangePage}
          handleSortChange={handleSortChange}
          isPaginating={isPaginating}
          isSearching={isSearching}
          isSorting={isSorting}
          itemsPerPage={itemsPerPage}
          carriers={carriers}
          carriersLoading={carriersLoading}
          setShowConfirmDelete={setShowConfirmDelete}
          sortDirection={sortDirection}
          sortField={sortField}
          totalItems={totalItems}
        />
      </div>
      <div className="max-lg:hidden">
        <CarrierTableView
          currentPage={currentPage}
          handleItemsPerPageChange={handleItemsPerPageChange}
          handleChangePage={handleChangePage}
          handleSortChange={handleSortChange}
          isPaginating={isPaginating}
          isSearching={isSearching}
          isSorting={isSorting}
          itemsPerPage={itemsPerPage}
          carriers={carriers}
          carriersLoading={carriersLoading}
          open={open}
          setOpen={setOpen}
          setShowConfirmDelete={setShowConfirmDelete}
          sortDirection={sortDirection}
          sortField={sortField}
          totalItems={totalItems}
        />
      </div>
    </div>
  );
}

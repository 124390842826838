import { NumericFormat } from "react-number-format";

export default function CurrencyInput({ label, required }) {
  return (
    <div className="col-span-2">
      <label
        htmlFor="currency-input"
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
      >
        {label} {required ? <span className="text-red-500">*</span> : ""}
      </label>
      <NumericFormat
        thousandSeparator={true}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
        allowNegative={false}
        type="text"
        name="currency-input"
        id="currency-input"
        className="mt-1 block w-full rounded-md dark:bg-zinc-800 dark:ring-zinc-800 dark:text-zinc-100 border dark:border-none border-zinc-300 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
      />
    </div>
  );
}

import { Link } from "react-router-dom";

const programs = [
  {
    name: "Lloyds - DIC & Earthquake [CA]",
    description: "DIC & Earthquake",
  },
  {
    name: "Great Lakes - Earthquake [CA]",
    description: "Earthquake only",
  },
  {
    name: "Flood [NJ, FL]",
    description: "Monoline flood rater",
  },
  {
    name: "Great Lakes Smalpac [CA]",
    description: "Commercial package",
  },
  {
    name: "Lloyds Commercial Lines [NJ]",
    description: "Package",
  },
  {
    name: "Lloyds - AOP v2 [CA]",
    description: "Commercial property",
  },
  {
    name: "Great Lakes Smalpac [NJ]",
    description: "Commercial package program",
  },
  {
    name: "Lloyds Condo Association [NJ]",
    description: "Condo package, property and liability",
  },
];

export default function Programs() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8">
      <div className="mx-auto">
        <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
          What program do you want to use?
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 pt-8">
        {programs.map(program => (
          <Link to="/quote/insured" key={program.name}>
            <div className="relative flex items-center space-x-3 rounded-lg bg-white hover:bg-zinc-200 dark:bg-zinc-900 hover:dark:bg-zinc-800 px-6 py-5 shadow-sm ring-1 ring-inset ring-zinc-200 dark:ring-zinc-800">
              <div className="min-w-0 flex-1">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="font-medium text-zinc-900 dark:text-zinc-100">
                  {program.name}
                </p>
                <p className="text-xs font-light text-zinc-500 dark:text-zinc-400 mt-2">
                  {program.description}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default function TextInput({ label, required }) {
  return (
    <div className="col-span-2">
      <label
        htmlFor="text-input"
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 text-ellipsis"
      >
        {label} {required ? <span className="text-red-500">*</span> : ""}
      </label>
      <input
        type="text"
        name="text-input"
        id="text-input"
        className="mt-1 h-8 px-2 block w-full rounded-md dark:bg-zinc-800 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 dark:text-zinc-100 shadow-sm sm:text-sm"
      />
    </div>
  );
}

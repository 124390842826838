import { Fragment, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { BsHourglassSplit } from "react-icons/bs";
import { Combobox, Dialog, Transition } from "@headlessui/react";

const linkedTasks = [
  {
    title: "Example Task Title 1",
    icon: BsHourglassSplit,
    color: "green",
    status: "In Progress",
    board: "Issue",
    created_date: "2023-10-05",
    created_by: "Alex Van Vleet",
    description:
      "In this pivotal role at our esteemed insurance company, you will be tasked with the responsibility of meticulously reviewing and processing insurance claims. As a key contributor to our mission, you will play a crucial part in delivering timely support to our policyholders during their times of need. <br /><br /> Your primary focus will revolve around verifying the legitimacy and accuracy of insurance claims submitted by our valued policyholders. This entails a comprehensive examination of the provided documentation, ensuring that all necessary details are considered. Your keen attention to detail will be instrumental in maintaining the integrity of the claims process. <br /><br /> Effective communication will be paramount as you liaise with policyholders, claimants, and relevant third parties. Your role will also involve adhering to regulatory and company policies, making informed claim decisions, and providing exceptional customer service throughout the claims journey. By joining our team, you will contribute to our commitment to excellence in the insurance industry, making a meaningful impact on the lives of our policyholders.",
  },
  {
    title: "Example Task Title 2",
    icon: BsHourglassSplit,
    color: "green",
    status: "In Progress",
    board: "Issue",
    created_date: "2023-02-22",
    created_by: "Not Alex Van Vleet",
    description:
      "In this pivotal role at our esteemed insurance company, you will be tasked with the responsibility of meticulously reviewing and processing insurance claims. As a key contributor to our mission, you will play a crucial part in delivering timely support to our policyholders during their times of need. <br /><br /> Your primary focus will revolve around verifying the legitimacy and accuracy of insurance claims submitted by our valued policyholders. This entails a comprehensive examination of the provided documentation, ensuring that all necessary details are considered. Your keen attention to detail will be instrumental in maintaining the integrity of the claims process. <br /><br /> Effective communication will be paramount as you liaise with policyholders, claimants, and relevant third parties. Your role will also involve adhering to regulatory and company policies, making informed claim decisions, and providing exceptional customer service throughout the claims journey. By joining our team, you will contribute to our commitment to excellence in the insurance industry, making a meaningful impact on the lives of our policyholders.",
  },
  {
    title: "Example Task Title 3",
    icon: BsHourglassSplit,
    color: "green",
    status: "In Progress",
    board: "Issue",
    created_date: "2023-05-17",
    created_by: "George Washington",
    description:
      "In this pivotal role at our esteemed insurance company, you will be tasked with the responsibility of meticulously reviewing and processing insurance claims. As a key contributor to our mission, you will play a crucial part in delivering timely support to our policyholders during their times of need. <br /><br /> Your primary focus will revolve around verifying the legitimacy and accuracy of insurance claims submitted by our valued policyholders. This entails a comprehensive examination of the provided documentation, ensuring that all necessary details are considered. Your keen attention to detail will be instrumental in maintaining the integrity of the claims process. <br /><br /> Effective communication will be paramount as you liaise with policyholders, claimants, and relevant third parties. Your role will also involve adhering to regulatory and company policies, making informed claim decisions, and providing exceptional customer service throughout the claims journey. By joining our team, you will contribute to our commitment to excellence in the insurance industry, making a meaningful impact on the lives of our policyholders.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LinkedTasks() {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(true);

  const filteredLinkedTasks =
    query === ""
      ? linkedTasks
      : linkedTasks.filter((task) => {
          return task.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-25 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {filteredLinkedTasks.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {filteredLinkedTasks.map((task) => (
                      <Combobox.Option
                        key={task.title}
                        value={task}
                        className={({ active }) =>
                          classNames(
                            "cursor-default select-none px-4 py-2",
                            active && "bg-zinc-200 cursor-pointer"
                          )
                        }
                      >
                        <div className="flex flex-col justify-center gap-1">
                          <h3 className="text-lg font-semibold">
                            {task.title}
                          </h3>
                          <div className="flex flex-wrap items-center gap-2 text-sm">
                            <div
                              className="flex items-center gap-1 px-2 py-0.5 rounded-full text-zinc-100"
                              style={{ backgroundColor: `${task.color}` }}
                            >
                              <task.icon
                                className="h-3 w-3 shrink-0"
                                aria-hidden="true"
                              />
                              <p>{task.status}</p>
                            </div>
                            <p className="text-zinc-700 dark:text-zinc-300">
                              {task.board} created {task.created_date} by{" "}
                              <strong className="cursor-pointer">
                                {task.created_by}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== "" && filteredLinkedTasks.length === 0 && (
                  <p className="p-4 text-sm text-gray-500">No people found.</p>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

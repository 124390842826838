import { useState, useEffect, Fragment, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import QuoteTable from "./QuoteTable";
import SearchSort from "../SearchSort";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import Loader from "../Loader";
const AddNewInsuredModal = lazy(() => import("./AddNewInsuredModal"));
const ExistingInsuredModal = lazy(() => import("./ExistingInsuredModal"));

export default function Quote() {
  const [existingInsuredModalOpen, setExistingInsuredModalOpen] =
    useState(false);
  const [newInsuredModalOpen, setNewInsuredModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm] = useState(null);
  const [sortField, setSortField] = useState("createdOn");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    Axios.CancelToken.source()
  );

  useEffect(() => {
    if (searchTerm !== null) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    return () =>
      cancelTokenSource.cancel("Component unmounted and request is cancelled");
  }, [cancelTokenSource]);

  const fetchQuotes = async () => {
    cancelTokenSource.cancel("Operation canceled due to new request.");

    const newCancelTokenSource = Axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    setIsSearching(!!searchTerm);
    setIsSorting(!!sortField);

    let url = `policy/quote?count=true&top=${itemsPerPage}&skip=${
      (currentPage - 1) * itemsPerPage
    }&$expand=Insured,Status,Program&$filter=isDeleted eq false`;

    if (searchTerm) {
      url += ` and (contains(name, '${searchTerm}'))`;
    }

    if (sortField) {
      url += `&$orderby=${sortField} ${sortDirection}`;
    }

    try {
      const response = await Axios.get(url, {
        cancelToken: newCancelTokenSource.token,
      });
      console.log("API Response:", response.data);
      setIsSearching(false);
      setIsSorting(false);

      if (response.data) {
        setTotalItems(response.headers["count"]);
        return response.data;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      setIsSearching(false);
      setIsSorting(false);
      if (Axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching quotes:", error);
      }
      return [];
    }
  };

  const { data: quotes, isLoading: quotesLoading } = useQuery(
    ["quote", currentPage, itemsPerPage, searchTerm, sortField, sortDirection],
    fetchQuotes,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleSortChange = field => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
    setCurrentPage(1);
  };

  const handleChangePage = async newPage => {
    setIsPaginating(true);
    setCurrentPage(newPage);
    await fetchQuotes();
    setIsPaginating(false);
  };

  const handleItemsPerPageChange = event => {
    const newValue = event.target.value;
    setItemsPerPage(Number(newValue));
    setCurrentPage(1);
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        {existingInsuredModalOpen && (
          <ExistingInsuredModal
            open={existingInsuredModalOpen}
            setOpen={setExistingInsuredModalOpen}
          />
        )}
        {newInsuredModalOpen && (
          <AddNewInsuredModal
            open={newInsuredModalOpen}
            setOpen={setNewInsuredModalOpen}
          />
        )}
      </Suspense>

      <div className="px-4 sm:px-6 lg:px-8 py-8">
        <div className="mx-auto">
          <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
            Manage Your Quotes
          </h2>
          <p className="leading-8 text-zinc-600 dark:text-zinc-300">
            Create a new quote, view existing quotes, or import from AIM
          </p>
        </div>
        <div className="flex items-center gap-2 text-zinc-900 dark:text-zinc-100 pb-2 mt-5">
          <SearchSort />
          <div className="inline-flex rounded-md shadow-sm">
            <button
              className="relative inline-flex items-center rounded-l-md bg-[#4d7c0f]/80 px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10 whitespace-nowrap"
              onClick={() => setNewInsuredModalOpen(true)}
            >
              Add Quote
            </button>
            <Menu as="div" className="relative -ml-px block">
              <Menu.Button className="relative inline-flex items-center rounded-r-md bg-[#4d7c0f]/80 px-2 py-2 text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10">
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item
                      className="cursor-pointer"
                      onClick={() => setNewInsuredModalOpen(true)}
                    >
                      <div className="flex gap-2 px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:bg-zinc-100 hover:dark:bg-zinc-700 hover:text-zinc-900 hover:dark:text-zinc-100">
                        <CheckBadgeIcon className="mt-1 h-5 w-5" />
                        <div className="flex flex-col gap-1">
                          <span className="font-semibold text-base">New</span>
                          <span className="text-sm font-light">
                            Add insured manually
                          </span>
                        </div>
                      </div>
                    </Menu.Item>

                    <Menu.Item
                      className="cursor-pointer"
                      onClick={() => setExistingInsuredModalOpen(true)}
                    >
                      <div className="flex gap-2 px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:bg-zinc-100 hover:dark:bg-zinc-700 hover:text-zinc-900 hover:dark:text-zinc-100">
                        <MagnifyingGlassIcon className="mt-1 h-5 w-5" />
                        <div className="flex flex-col gap-1">
                          <span className="font-semibold text-base">
                            Existing
                          </span>
                          <span className="text-sm font-light">
                            Find an existing insured
                          </span>
                        </div>
                      </div>
                    </Menu.Item>

                    <Menu.Item>
                      <Link
                        to="/quote"
                        className="flex gap-2 px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:bg-zinc-100 hover:dark:bg-zinc-700 hover:text-zinc-900 hover:dark:text-zinc-100"
                      >
                        <ArrowDownTrayIcon className="mt-1 h-5 w-5" />
                        <div className="flex flex-col gap-1">
                          <span className="font-semibold text-base">AIM</span>
                          <span className="text-sm font-light">
                            Import from AIM
                          </span>
                        </div>
                      </Link>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <QuoteTable
          quotes={quotes}
          currentPage={currentPage}
          handleItemsPerPageChange={handleItemsPerPageChange}
          handleChangePage={handleChangePage}
          handleSortChange={handleSortChange}
          isPaginating={isPaginating}
          isSearching={isSearching}
          isSorting={isSorting}
          itemsPerPage={itemsPerPage}
          quotesLoading={quotesLoading}
          sortDirection={sortDirection}
          sortField={sortField}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}

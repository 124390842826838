import { LuChevronLeft, LuChevronRight } from "react-icons/lu";

export default function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
  totalItems,
}) {
  const start = (currentPage - 1) * itemsPerPage + 1;
  const end = Math.min(start + itemsPerPage - 1, totalItems);

  const getPageNumbers = () => {
    let pages = [];
    if (totalPages <= 5) {
      for (let p = 1; p <= totalPages; p++) {
        pages.push(p);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) {
        pages.push("...");
      }
      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(currentPage + 1, totalPages - 1);
      if (currentPage === 1) {
        endPage = 3;
      }
      if (currentPage === totalPages) {
        startPage = totalPages - 2;
      }
      for (let p = startPage; p <= endPage; p++) {
        pages.push(p);
      }
      if (currentPage < totalPages - 2) {
        pages.push("...");
      }
      if (currentPage !== totalPages || totalPages === 4) {
        pages.push(totalPages);
      }
    }
    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="flex lg:flex-wrap max-lg:flex-col max-md:gap-y-3 items-center justify-between py-3">
      <div className="flex items-center gap-5">
        <div className="flex items-center text-sm text-zinc-700 dark:text-zinc-300 whitespace-nowrap">
          Rows per page:
          <select
            id="items-per-page"
            value={itemsPerPage}
            onChange={onItemsPerPageChange}
            className="ml-2 border-none rounded-md text-sm h-9 dark:bg-zinc-800 cursor-pointer ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800"
          >
            {[5, 10, 25].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <p className="text-sm text-zinc-700 dark:text-zinc-300 self-center">
          Showing <span className="font-medium">{start}</span> to{" "}
          <span className="font-medium">{end}</span> of{" "}
          <span className="font-medium">
            {parseInt(totalItems).toLocaleString()}
          </span>{" "}
          results
        </p>
      </div>
      <nav className="isolate inline-flex -space-x-px" aria-label="Pagination">
        <button
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
          className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 ${
            currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={currentPage === 1}
        >
          <LuChevronLeft className="h-4 w-4 mr-1" aria-hidden="true" />
          Prev
        </button>
        {pageNumbers.map((page, index) =>
          page === "..." ? (
            <span
              key={index}
              className="inline-flex items-center px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300"
            >
              {page}
            </span>
          ) : (
            <button
              key={index}
              onClick={() => onPageChange(page)}
              className={`relative inline-flex items-center px-4 py-2 text-sm ${
                currentPage === page
                  ? "text-zinc-900 dark:text-zinc-100"
                  : "text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
              }`}
            >
              {parseInt(page).toLocaleString()}
            </button>
          )
        )}
        <button
          onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
          className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 ${
            currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={currentPage === totalPages}
        >
          Next
          <LuChevronRight className="h-4 w-4 ml-1" aria-hidden="true" />
        </button>
      </nav>
    </div>
  );
}

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import CurrencyInput from "./inputs/CurrencyInput";
import DateInput from "./inputs/DateInput";
import MultiSelectDropdown from "./inputs/MultiSelectDropdown";
import NumberInput from "./inputs/NumberInput";
import SelectDropdown from "./inputs/SelectDropdown";
import TextInput from "./inputs/TextInput";

export default function CollapsibleTable() {
  return (
    <div className="w-full dark:bg-zinc-900 border border-zinc-300 dark:border-none rounded-lg">
      <div className="mx-auto">
        <Disclosure
          as="div"
          className="divide-y divide-zinc-300 dark:divide-zinc-800 px-4"
          defaultOpen
        >
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full py-3 font-semibold text-left text-zinc-900 dark:text-zinc-100 focus:outline-none rounded-lg">
                <span>Dynamic Section</span>
                <ChevronUpIcon
                  className={`${
                    open ? "transform rotate-180" : ""
                  } w-5 h-5 text-zinc-900 dark:text-zinc-100`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="py-4 text-sm text-zinc-500 rounded-b">
                <div className="grid grid-cols-6 gap-4">
                  <TextInput />
                  <NumberInput />
                  <CurrencyInput />
                  <SelectDropdown />
                  <MultiSelectDropdown />
                  <DateInput />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

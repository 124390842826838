import { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Pagination from "../Pagination";
import TasksViewSwitcher from "./TasksViewSwitcher";
import TaskSort from "./TaskSort";

const tags = {
  Label: "text-white bg-[#1f75cb]",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TaskList({
  format,
  isList,
  setIsList,
  statuses,
  tasks,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("newest");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("Open");
  const itemsPerPage = 10;

  const filteredTasks = tasks?.filter(
    (task) =>
      task.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.Description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedTasks = [...filteredTasks];
  const sortFunction = (a, b) => {
    switch (sortOption) {
      case "newest":
        return a.createdDate > b.createdDate ? -1 : 1;
      case "oldest":
        return a.createdDate > b.createdDate ? 1 : -1;
      case "recently-updated":
        return a.updatedDate > b.updatedDate ? -1 : 1;
      case "due-date":
        return a.DueDate > b.DueDate ? 1 : -1;
      case "a-z":
        return a.Title.toLowerCase() > b.Title.toLowerCase() ? 1 : -1;
      case "z-a":
        return a.Title.toLowerCase() < b.Title.toLowerCase() ? 1 : -1;
      default:
        return 0;
    }
  };
  sortedTasks.sort(sortFunction);

  const paginateTasks = (tasks, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return tasks.slice(startIndex, endIndex);
  };

  const paginatedTasks = paginateTasks(sortedTasks, currentPage, itemsPerPage);

  const openTasks = tasks.filter(
    (task) => task.Status === "In Progress" || task.Status === "Todo"
  );

  const filteredTasksByCategory =
    selectedCategory === "All"
      ? paginatedTasks
      : selectedCategory === "Open"
      ? openTasks
      : selectedCategory === "Completed"
      ? paginatedTasks.filter((task) => task.Status === "Complete")
      : [];

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-screen-xl w-full flex flex-col justify-center gap-5 px-10 py-10">
        <div className="flex justify-between items-center gap-10 dark:text-white">
          <form className="relative flex flex-1 h-full py-2 px-3 border border-zinc-500 max-w-[800px] rounded-full">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 h-full w-5 text-zinc-600 dark:text-zinc-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block h-full border-0 w-full py-0 pl-8 pr-5 pr-0 text-zinc-900 dark:text-white placeholder:text-zinc-600 dark:placeholder:text-zinc-400 bg-transparent focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
              autoComplete="off"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </form>
          <div className="flex items-center gap-3">
            <TasksViewSwitcher isList={isList} setIsList={setIsList} />
            <button className="flex justify-center items-center px-2 py-0.5 ring-1 ring-inset ring-zinc-900 dark:ring-zinc-100 rounded text-sm whitespace-nowrap">
              Bulk Edit
            </button>
            <button className="flex justify-center items-center px-2 py-0.5 bg-green-700 rounded text-sm text-white">
              New
            </button>
          </div>
        </div>

        <ul>
          <li>
            <div className="flex justify-between items-center dark:text-white text-sm">
              <div className="flex items-center gap-5">
                {["Open", "Completed", "All"].map((category) => (
                  <div
                    key={category}
                    onClick={() => setSelectedCategory(category)}
                    className={`cursor-pointer py-3 ${
                      selectedCategory === category
                        ? "border-b-2 border-green-700"
                        : ""
                    }`}
                  >
                    {category}{" "}
                    <span className="px-2 py-0.5 text-xs bg-zinc-300 dark:bg-zinc-600 rounded-full">
                      {category === "All"
                        ? tasks.length
                        : category === "Open"
                        ? openTasks.length
                        : tasks.filter((task) => task.Status === "Complete")
                            .length}
                    </span>
                  </div>
                ))}
              </div>
              <TaskSort setSortOption={setSortOption} />
            </div>
          </li>
          {filteredTasksByCategory.map((task) => (
            <li
              key={task.Id}
              className="flex flex-col md:flex-row md:items-center md:justify-between gap-x-10 py-2 border-t border-zinc-100 dark:border-zinc-600"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <a
                    href={`/task/${format(task.Id)}`}
                    className="text-sm font-bold leading-6 text-zinc-900 dark:text-zinc-100 max-w-[800px] hover:text-[/1f75cb] dark:hover:text-[#1f75cb] hover:underline"
                  >
                    {task.Title ?? "Example Task Title"}
                  </a>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500 dark:text-gray-400">
                  <p
                    className={classNames(
                      statuses[task.Status],
                      "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset w-fit"
                    )}
                  >
                    {task.Status}
                  </p>
                  <p>/{task?.Id}</p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p>{task?.BoardId}</p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="whitespace-nowrap">
                    Created {task?.DueDate ?? "10-19-2023"}
                  </p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="truncate">
                    Created by{" "}
                    <span className="text-zinc-100">
                      {task?.CreatedBy ?? "Alex Van Vleet"}
                    </span>
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-sm leading-5 text-gray-500 dark:text-gray-400">
                  {task.Description.length > 100
                    ? `${task.Description.substring(0, 100)} ...`
                    : task.Description}
                </div>
              </div>
              <div className="flex md:flex-col md:justify-center gap-1">
                {task.Tags && (
                  <p
                    className={classNames(
                      tags[task.Tags],
                      "rounded-full whitespace-nowrap px-2 py-0.5 text-xs font-medium w-fit"
                    )}
                  >
                    {task.Tags}
                  </p>
                )}
                <p className="text-xs dark:text-white whitespace-nowrap">
                  Due Date:{" "}
                  <span className="text-zinc-400 text-sm">
                    {task.DueDate ?? "N/A"}
                  </span>
                </p>
                <p className="text-xs dark:text-white whitespace-nowrap">
                  Assigned to:{" "}
                  <span className="text-zinc-400 text-sm">
                    {task.AssignedTo ?? "N/A"}
                  </span>
                </p>
              </div>
            </li>
          ))}
        </ul>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalTasks={sortedTasks.length}
          itemsPerPage={itemsPerPage}
          tasks={tasks}
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import {
  formatQuery,
  defaultValidator,
  QueryBuilder,
} from "react-querybuilder";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import "react-querybuilder/dist/query-builder.scss";
import { inputTypes } from "../../resources/inputTypes";
import CustomValueEditor from "./CustomValueEditor";
import CustomFieldSelector from "./CustomFieldSelector";
import CustomRemoveAction from "./CustomRemoveAction";

const initialQuery = { rules: [] };

const getOperators = (field, { fieldData }) => {
  if (fieldData?.fieldType === "Yes/No") {
    return [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
    ];
  }
  const inputType = inputTypes.find(
    (type) => type.name === fieldData?.fieldType
  );
  return inputType
    ? inputType.operators.map((op) => ({ name: op, label: op }))
    : [];
};

const getValueEditorType = (field, operator, { fieldData }) => {
  if (fieldData?.fieldType === "Yes/No") {
    return "checkbox";
  }
  return null;
};

const getDefaultValue = (rule, { fieldData }) => {
  if (fieldData?.fieldType === "Yes/No") {
    return false;
  }
  return "";
};

export default function QueryBuilderComponent() {
  const [query, setQuery] = useState(initialQuery);
  const { getAccessTokenSilently } = useAuth0();

  const fetchFields = async () => {
    const url =
      "https://insurtechies.dev/api/policy/inputfield?$filter=isDeleted eq false";

    try {
      const token = await getAccessTokenSilently();
      const response = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return (
        response.data.map((field) => ({
          name: field.name,
          label: field.name,
          value: field.name,
          fieldType: field.fieldType,
          listData: field.listData,
        })) || []
      );
    } catch (error) {
      console.error("Error fetching input fields:", error);
      return [];
    }
  };

  const { data: fields = [] } = useQuery(["inputField"], fetchFields, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleQueryChange = (newQuery) => {
    const updatedQuery = {
      ...newQuery,
      rules: newQuery.rules.map((rule) => {
        if (rule.field === "") {
          return { ...rule, operator: "", value: "" };
        }
        return rule;
      }),
    };
    setQuery(updatedQuery);
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={handleQueryChange}
          addRuleToNewGroups
          autoSelectField={false}
          resetOnOperatorChange
          validator={defaultValidator}
          controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          controlElements={{
            valueEditor: CustomValueEditor,
            fieldSelector: CustomFieldSelector,
            removeRuleAction: CustomRemoveAction,
            removeGroupAction: CustomRemoveAction,
          }}
          getOperators={getOperators}
          getValueEditorType={getValueEditorType}
          getDefaultValue={getDefaultValue}
        />
      </QueryBuilderDnD>
      <button
        className="px-2 py-1 bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] rounded mt-5 text-sm cursor-pointer"
        onClick={() => console.log(formatQuery(query, "sql"))}
      >
        Log Query
      </button>
    </div>
  );
}

import React from "react";
import { useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import Loader from "../../Loader";
import usePageDesign from "hooks/usePageDesign";

export default function Policy() {
  const { pathname } = useLocation();
  const lookupKey = pathname.split("/").pop();
  const { containerData, inputFieldsLoading, componentMapping } =
    usePageDesign(lookupKey);

  if (inputFieldsLoading) return <Loader />;

  const renderInput = (field) => {
    const fieldType = field.fieldType ? field.fieldType.toLowerCase() : "text";
    console.log(`Rendering input for fieldType: ${fieldType}`);

    const InputComponent = componentMapping[fieldType] || componentMapping.text;

    return (
      <li key={field.id} className="flex flex-col">
        <InputComponent
          label={field.name}
          defaultValue={field.name}
          required={field.required || true}
        />
      </li>
    );
  };

  return (
    <div className="w-full h-full text-zinc-900 dark:text-zinc-100 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col gap-y-4 mt-5">
        {containerData.map((container, index) => (
          <div
            key={index}
            className="w-full border border-zinc-300 dark:border-none rounded-lg"
          >
            <Disclosure
              as="div"
              className="divide-y divide-zinc-300 dark:divide-zinc-800"
              defaultOpen
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex justify-between w-full py-3 font-semibold text-left text-zinc-900 dark:text-zinc-100 focus:outline-none rounded-lg px-4 ${
                      open ? "" : "bg-zinc-900"
                    }`}
                  >
                    <span>{container.container}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-zinc-900 dark:text-zinc-100`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-sm text-zinc-500 rounded-b p-4">
                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-8 xl:gap-x-8 mb-6">
                      {container.fields.map(renderInput)}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
        <div className="w-full border border-zinc-300 dark:border-none rounded-lg">
          <Disclosure
            as="div"
            className="divide-y divide-zinc-300 dark:divide-zinc-800"
            defaultOpen
          >
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`flex justify-between w-full py-3 font-semibold text-left text-zinc-900 dark:text-zinc-100 focus:outline-none rounded-lg px-4 ${
                    open ? "" : "bg-zinc-900"
                  }`}
                >
                  <span>Producer</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 text-zinc-900 dark:text-zinc-100`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="text-sm text-zinc-500 rounded-b p-4">
                  <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-8 xl:gap-x-8 mb-6">
                    <div>
                      <label
                        htmlFor="text-input"
                        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                      >
                        Test
                      </label>
                      <input
                        type="text"
                        name="text-input"
                        id="text-input"
                        className="mt-1 h-8 px-2 block w-full rounded-md dark:bg-zinc-800 ring-1 ring-zinc-300 dark:ring-zinc-700 dark:text-zinc-100 border dark:border-none border-zinc-300 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                      />
                    </div>
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  );
}

// import { useState } from "react";
// import { useQuery } from "@tanstack/react-query";
// import Axios from "axios";
// import InsuredTableView from "./InsuredTableView";
// import SearchSort from "../../SearchSort";
// import useOutsideClick from "../../../hooks/useOutsideClick";

// const sortOptions = [
//   { value: "full-name", label: "Full Name" },
//   { value: "date", label: "Date" },
// ];

// export default function Insured() {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [totalItems, setTotalItems] = useState(0);
//   const [isPaginating, setIsPaginating] = useState(false);
//   const [sortField, setSortField] = useState("");
//   const [isSearching, setIsSearching] = useState(false);
//   const [searchTerm, setSearchTerm] = useState(null);
//   const [sortDirection, setSortDirection] = useState("asc");
//   const [isSorting, setIsSorting] = useState(false);
//   const {
//     ref: sortRef,
//     isOpen: showSortOptions,
//     setIsOpen: setShowSortOptions,
//   } = useOutsideClick(false);

//   const fetchInsured = async () => {
//     setIsPaginating(true);

//     setIsSearching(!!searchTerm);
//     setIsSorting(!!sortField);

//     let url = `policy/insured?top=${itemsPerPage}&skip=${
//       (currentPage - 1) * itemsPerPage
//     }`;

//     if (searchTerm) {
//       url += `&$filter=startswith(fullName, '${searchTerm}') or startswith(date, '${searchTerm}')`;
//     }

//     try {
//       const response = await Axios.get(url);
//       setIsSearching(false);
//       setIsPaginating(false);
//       if (response.data) {
//         setTotalItems(response.headers["count"]);
//         return response.data;
//       } else {
//         throw new Error("No data returned from the API");
//       }
//     } catch (error) {
//       setIsSearching(false);
//       setIsPaginating(false);
//       console.error("Error fetching insured data:", error);
//       return [];
//     }
//   };

//   const { data: insuredData, isLoading: insuredLoading } = useQuery(
//     ["insured", currentPage, itemsPerPage],
//     fetchInsured,
//     {
//       keepPreviousData: true,
//       refetchOnWindowFocus: false,
//     }
//   );

//   const handleSortChange = field => {
//     const newDirection =
//       sortField === field && sortDirection === "asc" ? "desc" : "asc";
//     setSortField(field);
//     setSortDirection(newDirection);
//     setCurrentPage(1);
//   };

//   const handleChangePage = async newPage => {
//     setCurrentPage(newPage);
//     await fetchInsured();
//   };

//   const handleItemsPerPageChange = newSize => {
//     setItemsPerPage(newSize);
//     setCurrentPage(1);
//   };

//   return (
//     <div className="px-4 sm:px-6 lg:px-8">
//       <div className="flex items-center gap-5 text-zinc-900 dark:text-zinc-100 pb-2">
//         <SearchSort
//           isSearching={isSearching}
//           isSorting={isSorting}
//           sortOptions={sortOptions}
//           setSearchTerm={setSearchTerm}
//           handleSortChange={handleSortChange}
//           showSortOptions={showSortOptions}
//           setShowSortOptions={setShowSortOptions}
//           sortRef={sortRef}
//         />
//         <div className="flex items-center gap-2">
//           <button className="px-4 py-2 text-sm font-semibold rounded-lg text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800">
//             Add
//           </button>
//         </div>
//       </div>
//       <InsuredTableView
//         currentPage={currentPage}
//         handleChangePage={handleChangePage}
//         handleItemsPerPageChange={handleItemsPerPageChange}
//         isPaginating={isPaginating}
//         itemsPerPage={itemsPerPage}
//         insuredData={insuredData}
//         insuredLoading={insuredLoading}
//         totalItems={totalItems}
//       />
//     </div>
//   );
// }

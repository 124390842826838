import { Link } from "react-router-dom";

export default function MobileStepper({
  steps,
  updatedSteps,
  currentStepIndex,
}) {
  const completedSteps = updatedSteps.filter(
    (step) => step.status === "complete"
  ).length;
  const totalSteps = steps.length;
  const currentStep = currentStepIndex + 1;
  const radius = 60;
  const circumference = 2 * Math.PI * radius;
  const completedFraction = completedSteps / totalSteps;
  const dashArray = circumference;
  const dashOffset = circumference * (1 - completedFraction);

  return (
    <div className="flex items-center gap-5 px-4 sm:px-6 lg:px-8 py-10 md:hidden">
      <svg
        width="80"
        height="80"
        viewBox="0 0 128 128"
        className="transform -rotate-90"
      >
        <circle
          cx="64"
          cy="64"
          r={radius}
          fill="transparent"
          stroke="#3f3f46"
          strokeWidth="8"
        />
        <circle
          cx="64"
          cy="64"
          r={radius}
          fill="transparent"
          stroke="#4d7c0f"
          strokeWidth="8"
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        />
        <g transform="rotate(90, 64, 64)">
          <text
            x="64"
            y="64"
            fill="white"
            dy=".3em"
            textAnchor="middle"
            className="text-2xl font-bold"
          >
            {currentStep} of {totalSteps}
          </text>
        </g>
      </svg>
      <div className="flex flex-col gap-y-1">
        <span className="text-lg font-bold text-zinc-900 dark:text-zinc-100 whitespace-nowrap">
          {updatedSteps[currentStepIndex]?.name}
        </span>
        {currentStep < updatedSteps.length && (
          <Link
            to={`/quote/${updatedSteps[currentStepIndex + 1]?.href}`}
            className="font-semibold text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 whitespace-nowrap"
          >
            Next: {updatedSteps[currentStepIndex + 1]?.name}
          </Link>
        )}
        {currentStep > 1 && (
          <Link
            to={`/quote/${updatedSteps[currentStepIndex - 1]?.href}`}
            className="font-semibold text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 whitespace-nowrap"
          >
            Previous: {updatedSteps[currentStepIndex - 1]?.name}
          </Link>
        )}
      </div>
    </div>
  );
}

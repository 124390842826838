export default function DateInput({ handleDateChange, label, value }) {
  return (
    <>
      {label === true ? (
        <div className="col-span-2">
          <label
            htmlFor="date-input"
            className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
          >
            Date Input
          </label>
          <input
            type="date"
            name="date-input"
            id="date-input"
            className="mt-1 block w-full rounded-md dark:bg-zinc-800 dark:ring-zinc-800 dark:text-zinc-100 border dark:border-none border-zinc-300 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
            value={value}
            onChange={handleDateChange}
          />
        </div>
      ) : (
        <input
          type="date"
          name="date-input"
          id="date-input"
          className="w-full max-w-[300px] text-sm placeholder:text-zinc-500 dark:placeholder:text-zinc-400 border-none h-[32px] rounded pr-6 dark:bg-zinc-800 ring-1 ring-inset dark:ring-zinc-700"
          value={value}
          onChange={handleDateChange}
        />
      )}
    </>
  );
}

import { useState, useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import ConfirmDelete from "components/ConfirmDelete";
import InputFieldTableView from "./InputFieldTableView";
import SearchSort from "components/SearchSort";
import useOutsideClick from "hooks/useOutsideClick";
import { LuPlus } from "react-icons/lu";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { Tooltip } from "react-tooltip";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import EditInputFieldModal from "./EditInputFieldModal";

const sortOptions = [
  { value: "address", label: "Address" },
  { value: "city", label: "City" },
  { value: "zip", label: "Zip" },
  { value: "county", label: "County" },
];

export default function InputFields() {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [editedInputField, setEditedInputField] = useState({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [newInputFieldModalOpen, setNewInputFieldModalOpen] = useState(false);
  const [selectedInputFieldId, setSelectedInputFieldId] = useState(null);
  const {
    ref: sortRef,
    isOpen: showSortOptions,
    setIsOpen: setShowSortOptions,
  } = useOutsideClick(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    Axios.CancelToken.source()
  );
  const pageParams = useParams();

  useEffect(() => {
    if (searchTerm !== null) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    return () =>
      cancelTokenSource.cancel("Component unmounted and request is cancelled");
  }, [cancelTokenSource]);

  const fetchInputFields = async () => {
    cancelTokenSource.cancel("Operation canceled due to new request.");

    const newCancelTokenSource = Axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    setIsSearching(!!searchTerm);
    setIsSorting(!!sortField);

    let url = `policy/inputfield?count=true&top=${itemsPerPage}&skip=${
      (currentPage - 1) * itemsPerPage
    }&$filter=programId eq ${pageParams.id} and isDeleted eq false`;

    if (searchTerm) {
      url += ` and (contains(name, '${searchTerm}') or contains(fieldType, '${searchTerm}'))`;
    }

    if (sortField) {
      url += `&$orderby=${sortField} ${sortDirection}`;
    }

    try {
      const response = await Axios.get(url, {
        cancelToken: newCancelTokenSource.token,
      });
      setIsSearching(false);
      setIsSorting(false);

      if (response.data) {
        setTotalItems(response.headers["count"]);
        return response.data;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      setIsSearching(false);
      setIsSorting(false);
      if (Axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching input field:", error);
      }
      return [];
    }
  };

  const {
    data: inputFields,
    isLoading: inputFieldsLoading,
    refetch,
  } = useQuery(
    [
      "inputField",
      currentPage,
      itemsPerPage,
      searchTerm,
      sortField,
      sortDirection,
    ],
    fetchInputFields,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleSortChange = field => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
    setCurrentPage(1);
  };

  const handleChangePage = async newPage => {
    setIsPaginating(true);
    setCurrentPage(newPage);
    await fetchInputFields();
    setIsPaginating(false);
  };

  const handleItemsPerPageChange = event => {
    const newValue = event.target.value;
    setItemsPerPage(Number(newValue));
    setCurrentPage(1);
  };

  const handleAdd = () => {
    setSelectedInputFieldId(null);
    setNewInputFieldModalOpen(true);
  };

  const handleEdit = (showPrompt, fieldId) => {
    setSelectedInputFieldId(fieldId);
    setNewInputFieldModalOpen(showPrompt);
  };

  const handleDeletePrompt = (showPrompt, fieldId) => {
    setShowConfirmDelete(showPrompt);
    setSelectedInputFieldId(fieldId);
  };

  const handleDelete = async () => {
    const response = await Axios.delete(
      `policy/inputField/${selectedInputFieldId}`
    );

    refetch();
    toast.success("Input Field successfully deleted");
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5">
      <Suspense fallback={<Loader />}>
        {newInputFieldModalOpen && (
          <EditInputFieldModal
            open={newInputFieldModalOpen}
            setOpen={setNewInputFieldModalOpen}
            programId={pageParams.id}
            selectedInputFieldId={selectedInputFieldId}
            refreshData={refetch}
          />
        )}
      </Suspense>
      {showConfirmDelete && (
        <ConfirmDelete
          open={showConfirmDelete}
          setOpen={setShowConfirmDelete}
          onConfirm={handleDelete}
        />
      )}
      <Tooltip id="actions-tooltip" />
      <div className="flex items-center gap-5 text-zinc-900 dark:text-zinc-100 pb-2">
        <SearchSort
          isSearching={isSearching}
          isSorting={isSorting}
          sortOptions={sortOptions}
          setSearchTerm={setSearchTerm}
          handleSortChange={handleSortChange}
          showSortOptions={showSortOptions}
          setShowSortOptions={setShowSortOptions}
          sortRef={sortRef}
        />
        <div className="flex items-center gap-2">
          <button
            className="flex items-center gap-2 px-4 py-2 text-sm font-semibold rounded-lg dark:text-zinc-300 text-white bg-primary hover:bg-primary-light dark:bg-zinc-800 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800"
            onClick={() => handleAdd()}
          >
            <LuPlus className="my-auto w-4 h-4" /> Add New Field
          </button>
        </div>
      </div>
      <InputFieldTableView
        currentPage={currentPage}
        editedInputField={editedInputField}
        handleItemsPerPageChange={handleItemsPerPageChange}
        handleChangePage={handleChangePage}
        handleSortChange={handleSortChange}
        isPaginating={isPaginating}
        isSearching={isSearching}
        isSorting={isSorting}
        itemsPerPage={itemsPerPage}
        inputFields={inputFields}
        inputFieldsLoading={inputFieldsLoading}
        open={open}
        setEditedInputField={setEditedInputField}
        setOpen={setOpen}
        setShowConfirmDelete={handleDeletePrompt}
        sortDirection={sortDirection}
        sortField={sortField}
        totalItems={totalItems}
        handleEdit={handleEdit}
      />
    </div>
  );
}

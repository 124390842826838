/* global google */
import { useState, useEffect, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import states from "states-us";

export default function LocationModal({
  open,
  setOpen,
  isLoaded,
  accessToken,
}) {
  const initialFormState = {
    name: "",
    dba: "",
    email: "",
    phone: "",
    streetAddress: "",
    streetAddressTwo: "",
    city: "",
    state: "",
    zip: "",
    locationNumber: "",
    buildingNumber: "",
    locationName: "",
    county: "",
    lat: "",
    lng: "",
  };

  const [form, setForm] = useState(initialFormState);
  const { user } = useAuth0();
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const contiguousStates = states.filter((state) => state.contiguous);

  useEffect(() => {
    if (!isLoaded || !open) return;

    const loadGoogleMaps = () => {
      if (typeof google === "undefined") {
        console.error("Google Maps JS API not loaded.");
        return;
      }

      const defaultMapCenter = { lat: 37.0902, lng: -95.7129 };
      const defaultZoom = 3.5;

      mapRef.current = new google.maps.Map(mapRef.current, {
        center: defaultMapCenter,
        zoom: defaultZoom,
      });

      const streetAddressInput = document.getElementById("street-address");

      if (streetAddressInput) {
        console.log("Initializing autocomplete...");
        const options = {
          types: ["address"],
          componentRestrictions: { country: "us" },
        };
        autocompleteRef.current = new google.maps.places.Autocomplete(
          streetAddressInput,
          options
        );

        autocompleteRef.current.addListener(
          "place_changed",
          handlePlaceChanged
        );

        streetAddressInput.focus();
      } else {
        console.error("Street address input not found.");
      }
    };

    const handlePlaceChanged = () => {
      const place = autocompleteRef.current.getPlace();
      if (!place.geometry) {
        console.error("Autocomplete place has no geometry.");
        return;
      }
      console.log("Place selected: ", place.name);

      const addressComponents = place.address_components;
      const formValues = {
        streetAddress: place.name,
        city: "",
        state: "",
        zip: "",
        county: "",
        lat: place.geometry.location.lat().toString(),
        lng: place.geometry.location.lng().toString(),
      };

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("locality")) {
          formValues.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          formValues.state = component.short_name;
        } else if (types.includes("postal_code")) {
          formValues.zip = component.long_name;
        } else if (types.includes("administrative_area_level_2")) {
          formValues.county = component.long_name;
        }
      });

      setForm((prevForm) => ({ ...prevForm, ...formValues }));

      mapRef.current.setCenter(place.geometry.location);
      mapRef.current.setZoom(15);
      new google.maps.Marker({
        position: place.geometry.location,
        map: mapRef.current,
        title: place.name,
      });
    };

    const timerId = setTimeout(loadGoogleMaps, 0);

    return () => {
      clearTimeout(timerId);
      if (autocompleteRef.current) {
        google.maps.event.clearInstanceListeners(autocompleteRef.current);
        autocompleteRef.current = null;
      }
    };
  }, [isLoaded, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let quoteId;

    try {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      const quoteResponse = await axios.post(
        "https://insurtechies.dev/api/policy/quote",
        {
          metadata: null,
          isDeleted: false,
          insuredId: "6e7865c4-4c0f-47c4-c6a8-08dc227f3930",
          createdBy: user?.sub,
          statusId: 0,
        },
        config
      );

      console.log("Quote response:", quoteResponse.data);

      if (quoteResponse.data && quoteResponse.data.Id) {
        quoteId = quoteResponse.data.Id;
      } else {
        console.error("Quote ID not found in response:", quoteResponse.data);
        throw new Error("Quote ID not found in response");
      }

      const addressResponse = await axios.post(
        "https://insurtechies.dev/api/policy/address",
        {
          id: quoteId,
          quoteId: quoteId,
          createdBy: user?.sub,
          addressData: {
            addressOne: form.streetAddress,
            addressTwo: form.streetAddressTwo,
            city: form.city,
            zip: form.zip,
            county: form.county,
          },
          latitude: parseFloat(form.lat),
          longitude: parseFloat(form.lng),
        },
        config
      );

      console.log(addressResponse.data);

      setForm(initialFormState);

      setOpen(false);
      toast.success("Location added successfully");
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      toast.error("Error adding location");
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            setOpen(false);
            setForm(initialFormState);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-zinc-500 dark:bg-zinc-950 bg-opacity-75 dark:bg-opacity-75 transition-opacity h-screen w-screen" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:max-w-7xl w-full">
                  <div className="absolute top-0 right-0 p-4">
                    <button
                      type="button"
                      className="inline-flex text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
                      onClick={() => {
                        setOpen(false);
                        setForm(initialFormState);
                      }}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex bg-zinc-900">
                    <div className="max-lg:hidden lg:w-2/5">
                      <div
                        id="map"
                        ref={mapRef}
                        className="h-full"
                        style={{ width: "100%", height: "100%" }}
                      ></div>
                    </div>
                    <div className="w-full lg:w-3/5 p-6 bg-white dark:bg-zinc-900 overflow-auto">
                      <form className="addNewInsuredForm">
                        <div className="rounded-lg bg-white dark:bg-zinc-900 p-4">
                          <div className="space-y-12">
                            <div className="border-b border-zinc-900/10 dark:border-zinc-100/10 pb-12">
                              <h2 className="text-base font-semibold leading-7 text-zinc-900 dark:text-zinc-100">
                                Add a Location
                              </h2>
                              <p className="mt-2 text-sm text-zinc-500 dark:text-zinc-400">
                                Fields marked with{" "}
                                <span className="text-red-500">*</span> are
                                required
                              </p>

                              <div className="mt-10 grid gap-x-6 gap-y-8 grid-cols-10">
                                <div className="col-span-full lg:col-span-6">
                                  <label
                                    htmlFor="street-address"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    Street Address{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="streetAddress"
                                      id="street-address"
                                      value={form.streetAddress}
                                      ref={autocompleteRef}
                                      onChange={handleInputChange}
                                      required
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-4">
                                  <label
                                    htmlFor="street-address-two"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    Street Address Two
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="streetAddressTwo"
                                      id="street-address-two"
                                      value={form.streetAddressTwo}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-3">
                                  <label
                                    htmlFor="location-number"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    Location #{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="locationNumber"
                                      id="location-number"
                                      value={form.locationNumber}
                                      onChange={handleInputChange}
                                      required
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-3">
                                  <label
                                    htmlFor="building-number"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    Building #{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="buildingNumber"
                                      id="building-number"
                                      value={form.buildingNumber}
                                      onChange={handleInputChange}
                                      required
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-4">
                                  <label
                                    htmlFor="location-name"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    Location Name
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="locationName"
                                      id="location-name"
                                      value={form.locationName}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-4">
                                  <label
                                    htmlFor="city"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    City <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="city"
                                      id="city"
                                      value={form.city}
                                      onChange={handleInputChange}
                                      required
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-3">
                                  <label
                                    htmlFor="state"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    State{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-2">
                                    <select
                                      name="state"
                                      id="state"
                                      value={form.state}
                                      onChange={handleInputChange}
                                      required
                                      className="block max-h-[300px] overflow-hidden w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    >
                                      <option value="">Select a state</option>
                                      {contiguousStates.map((state) => (
                                        <option
                                          key={state.abbreviation}
                                          value={state.abbreviation}
                                        >
                                          {state.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-3">
                                  <label
                                    htmlFor="zip"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    ZIP <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="zip"
                                      id="zip"
                                      value={form.zip}
                                      onChange={handleInputChange}
                                      required
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-4">
                                  <label
                                    htmlFor="county"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    County
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="county"
                                      id="county"
                                      value={form.county}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-3">
                                  <label
                                    htmlFor="lat"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    Latitude
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="lat"
                                      id="lat"
                                      value={form.lat}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div className="col-span-5 lg:col-span-3">
                                  <label
                                    htmlFor="lng"
                                    className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                                  >
                                    Longitude
                                  </label>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      name="lng"
                                      id="lng"
                                      value={form.lng}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                              type="button"
                              className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100"
                              onClick={() => {
                                setOpen(false);
                                setForm(initialFormState);
                              }}
                            >
                              Cancel
                            </button>
                            <Link to="/quote/insured">
                              <button
                                onClick={handleSubmit}
                                type="submit"
                                className="rounded-md bg-[#4d7c0f]/90 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                              >
                                Save
                              </button>
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import ListGridView from "./TasksViewSwitcher";

export default function TaskGrid({ isList, setIsList, statuses, tasks }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredTasks = tasks?.filter(
    (task) =>
      task.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.Description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const shortTitle = (task) =>
    task.Title.length > 50 ? `${task.Title.substring(0, 50)} ...` : task.Title;
  const shortDesc = (task) =>
    task.Description.length > 100
      ? `${task.Description.substring(0, 100)} ...`
      : task.Description;

  const taskCard = (task) => (
    <div className="flex flex-col justify-center gap-1 bg-white dark:bg-zinc-700 p-2 shadow-lg border border-zinc-300 dark:border-none rounded">
      <h5 className="text-sm font-semibold">{shortTitle(task)}</h5>
      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500 dark:text-gray-400">
        <p
          className={classNames(
            statuses[task.Status],
            "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset w-fit"
          )}
        >
          {task.Status}
        </p>
        <p>/{task?.Id}</p>
        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
          <circle cx={1} cy={1} r={1} />
        </svg>
        <p>{task?.BoardId}</p>
        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
          <circle cx={1} cy={1} r={1} />
        </svg>
        <p className="whitespace-nowrap">
          Created {task?.DueDate ?? "10-19-2023"}
        </p>
        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
          <circle cx={1} cy={1} r={1} />
        </svg>
        <p className="truncate">
          Created by{" "}
          <span className="text-zinc-100">
            {task?.CreatedBy ?? "Alex Van Vleet"}
          </span>
        </p>
      </div>
      <p
        className={classNames(
          tags[task.Tags],
          "rounded-full whitespace-nowrap px-2 py-0.5 text-xs font-medium w-fit"
        )}
      >
        {task.Tags}
      </p>
      <p className="text-xs">{shortDesc(task)}</p>
    </div>
  );

  const tags = {
    Label: "text-white bg-[#1f75cb]",
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-screen-xl xl:max-h-[calc(100vh-64px)] w-full flex flex-col justify-center gap-10 px-10 py-10">
        <div className="flex justify-between items-center gap-10 dark:text-white">
          <form className="relative flex flex-1 h-full py-2 px-3 border border-zinc-500 rounded-full">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 h-full w-5 text-zinc-600 dark:text-zinc-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block h-full border-0 w-full py-0 pl-8 pr-5 pr-0 text-zinc-900 dark:text-white placeholder:text-zinc-600 dark:placeholder:text-zinc-400 bg-transparent focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
              autoComplete="off"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </form>
          <ListGridView isList={isList} setIsList={setIsList} />
        </div>

        <div className="flex overflow-x-scroll">
          <div className="w-screen grid grid-cols-1 xl:grid-cols-3 gap-5 dark:text-white">
            {["In Progress", "Complete", "Archived"].map((status) => (
              <div
                key={status}
                className="flex flex-col h-full border border-zinc-500 dark:border-none bg-white dark:bg-zinc-900 flex-shrink-0 rounded overflow-y-scroll relative"
              >
                <div className="sticky top-0 flex justify-between items-center p-3 w-full border-b border-zinc-500 dark:border-none bg-white dark:bg-zinc-900">
                  <h5 className="text-lg">{status}</h5>
                  <EllipsisVerticalIcon className="h-5 w-5" />
                </div>
                <div className="flex flex-col gap-3 p-3">
                  {status === "In Progress" &&
                    filteredTasks
                      ?.filter((task) => task.Status === status)
                      .map((task) => taskCard(task))}
                  {status === "Complete" &&
                    filteredTasks
                      ?.filter((task) => task.Status === status)
                      .map((task) => taskCard(task))}
                  {status === "Archived" &&
                    filteredTasks
                      ?.filter((task) => task.Status === status)
                      .map((task) => taskCard(task))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";

export default function BooleanInput({ label, name, required }) {
  const [value, setValue] = useState(false);

  const handleOnChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className="col-span-2">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
      >
        {label} {required ? <span className="text-red-500">*</span> : ""}
      </label>
      <span className="isolate inline-flex rounded-md shadow-sm h-8 mt-1">
        <button
          type="button"
          value={false}
          onClick={() => handleOnChange(false)}
          className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm ring-1 ring-inset focus:z-10 ${
            value === false
              ? "bg-red-600 text-white ring-red-500"
              : "bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-50"
          }`}
        >
          False
        </button>
        <button
          type="button"
          value={true}
          onClick={() => handleOnChange(true)}
          className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm ring-1 ring-inset focus:z-10 ${
            value === true
              ? "bg-[#4d7c0f] text-white ring-[#4d7c0f]/25"
              : "bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-50"
          }`}
        >
          True
        </button>
      </span>
    </div>
  );
}

/* global google */
import { useState, useEffect, useRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CollapsibleTable from "../../CollapsibleTable";

const mockLocations = [
  {
    locationNumber: "25",
    buildingNumber: "44",
    address: "2001 Blake St",
    city: "Denver",
    state: "CO",
    zip: "80205",
    lat: 39.75634940326602,
    lng: -104.99412461160223,
  },
  {
    locationNumber: "37",
    buildingNumber: "82",
    address: "2205 19th St",
    city: "Denver",
    state: "CO",
    zip: "80202",
    lat: 39.76003630328987,
    lng: -105.00275734897772,
  },
  {
    locationNumber: "33",
    buildingNumber: "51",
    address: "1701 Bryant St",
    city: "Denver",
    state: "CO",
    zip: "80204",
    lat: 39.744233219126066,
    lng: -105.02022119474208,
  },
];

export default function LocationModal({ open, setOpen, isLoaded }) {
  const [showInputFields, setShowInputFields] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (!isLoaded || !open) return;

    const loadGoogleMaps = () => {
      if (typeof google === "undefined") {
        console.error("Google Maps JS API not loaded.");
        return;
      }

      const defaultMapCenter = { lat: 37.0902, lng: -95.7129 };
      const defaultZoom = 3.5;

      mapRef.current = new google.maps.Map(mapRef.current, {
        center: defaultMapCenter,
        zoom: defaultZoom,
      });
    };

    const timerId = setTimeout(loadGoogleMaps, 0);

    return () => {
      clearTimeout(timerId);
    };
  }, [isLoaded, open]);

  useEffect(() => {
    if (selectedLocation && mapRef.current) {
      const { lat, lng } = selectedLocation;
      const newCenter = new google.maps.LatLng(lat, lng);

      mapRef.current.setCenter(newCenter);
      mapRef.current.setZoom(15);

      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
      markerRef.current = new google.maps.Marker({
        position: newCenter,
        map: mapRef.current,
      });
    }
  }, [selectedLocation]);

  const handleLocationChange = (event) => {
    const locationIndex = event.target.value;
    setSelectedLocation(mockLocations[locationIndex]);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-zinc-500 dark:bg-zinc-950 bg-opacity-75 dark:bg-opacity-75 transition-opacity h-screen w-screen" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:max-w-7xl w-full">
                  <div className="absolute top-0 right-0 p-4">
                    <button
                      type="button"
                      className="inline-flex text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex bg-zinc-900">
                    <div className="hidden lg:block lg:w-2/5">
                      <div
                        id="map"
                        ref={mapRef}
                        className="h-full"
                        style={{ width: "100%", height: "100%" }}
                      ></div>
                    </div>
                    <div className="w-full lg:w-3/5 p-4 bg-white dark:bg-zinc-900 overflow-auto min-h-[800px]">
                      <header className="w-full border-b border-white/5 z-10 pb-2">
                        <nav className="flex overflow-x-auto">
                          <ul className="flex min-w-full flex-none gap-x-5 text-sm font-semibold leading-6 text-zinc-400">
                            <li
                              className={`cursor-pointer ${
                                showInputFields
                                  ? "text-[#4d7c0f]"
                                  : "text-zinc-900 dark:text-zinc-100"
                              }`}
                              onClick={() => setShowInputFields(true)}
                            >
                              Input Fields
                            </li>
                            <li
                              className={`cursor-pointer ${
                                !showInputFields
                                  ? "text-[#4d7c0f]"
                                  : "text-zinc-900 dark:text-zinc-100"
                              }`}
                              onClick={() => setShowInputFields(false)}
                            >
                              API Results
                            </li>
                          </ul>
                        </nav>
                      </header>

                      {showInputFields ? (
                        <div>
                          <select
                            onChange={handleLocationChange}
                            className="block max-h-[300px] overflow-hidden w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 dark:ring-0 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:text-sm sm:leading-6 mt-4 cursor-pointer"
                          >
                            <option value="">Select a location...</option>
                            {mockLocations.map((location, index) => (
                              <option key={index} value={index}>
                                {`Location #: ${location.locationNumber} - Building #: ${location.buildingNumber} - Address: ${location.address}, ${location.city}, ${location.state}, ${location.zip}`}
                              </option>
                            ))}
                          </select>

                          <div className="mt-5">
                            <CollapsibleTable />
                            <CollapsibleTable />
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center h-full w-full">
                          <img
                            src="https://media.tenor.com/f_IVECflloUAAAAi/adventure-time.gif"
                            alt=""
                            className="w-1/2"
                          />
                          <img
                            src="https://media.tenor.com/8rCNTNtl4pgAAAAi/adventure-time-bmq.gif"
                            alt=""
                            className="w-1/2"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

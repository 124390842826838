import { useState, useCallback, useEffect } from "react";

export default function ViewSwitcher({ listView, setListView }) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const savedViewType = localStorage.getItem("preferredViewType");
    const isListView = savedViewType ? savedViewType === "list" : listView;
    setIsActive(!isListView);
    setListView(isListView);
  }, [listView, setListView]);

  const toggle = useCallback(() => {
    setIsActive((current) => !current);
    setListView((prevListView) => {
      const newView = !prevListView;
      localStorage.setItem("preferredViewType", newView ? "list" : "grid");
      return newView;
    });
  }, [setListView]);

  const animationClass = "animation";
  const activeClass = !isActive ? "active" : "";

  return (
    <button
      className={`grid-list px-4 py-2 text-sm font-semibold rounded-lg text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800 ${animationClass} ${activeClass}`}
      onClick={toggle}
    >
      <div className="icon">
        <div className="lines">
          <i className="!bg-zinc-600 dark:!bg-zinc-300"></i>
          <i className="!bg-zinc-600 dark:!bg-zinc-300"></i>
          <i className="!bg-zinc-600 dark:!bg-zinc-300"></i>
          <i className="!bg-zinc-600 dark:!bg-zinc-300"></i>
        </div>
        <div className="dots">
          <i className="!bg-zinc-600 dark:!bg-zinc-300"></i>
          <i className="!bg-zinc-600 dark:!bg-zinc-300"></i>
          <i className="!bg-zinc-600 dark:!bg-zinc-300"></i>
          <i className="!bg-zinc-600 dark:!bg-zinc-300"></i>
        </div>
      </div>
      <div className="text">
        <span>Grid</span>
        <span>List</span>
      </div>
    </button>
  );
}

// import { Bars4Icon, Squares2X2Icon } from "@heroicons/react/24/outline";

// export default function ViewSwitcher({ listView, setListView }) {
//   return (
//     <div className="isolate inline-flex rounded-md shadow-sm">
//       <button
//         onClick={() => {
//           setListView(true);
//           localStorage.setItem("preferredViewType", "list");
//         }}
//         className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold bg-white dark:bg-zinc-800 text-zinc-600 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 focus:z-10 ${
//           listView === true ? "" : "dark:ring-0"
//         }`}
//       >
//         <Bars4Icon className="h-5 w-5" />
//       </button>
//       <button
//         onClick={() => {
//           setListView(false);
//           localStorage.setItem("preferredViewType", "card");
//         }}
//         className={`relative inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold bg-white dark:bg-zinc-800 text-zinc-600 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 focus:z-10 ${
//           listView === true ? "dark:ring-0" : ""
//         }`}
//       >
//         <Squares2X2Icon className="h-5 w-5" />
//       </button>
//     </div>
//   );
// }

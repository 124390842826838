import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";

export default function NotesApis() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    setIsFullScreen(false); // Reset fullscreen state when sidebar is closed
  };

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[200]" onClose={closeSidebar}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-zinc-900/80" />
          </Transition.Child>
          <div className="fixed inset-0 flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel
                className="relative flex w-full flex-1 transition-all duration-300 ease-in-out ring-1 ring-white/10"
                style={{
                  maxWidth: isFullScreen ? "80rem" : "20rem",
                }}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute flex justify-between items-center top-0 w-full h-16 px-4">
                    <button type="button" onClick={toggleFullscreen}>
                      {isFullScreen ? (
                        <GoSidebarCollapse
                          className="h-6 w-6 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
                          aria-hidden="true"
                        />
                      ) : (
                        <GoSidebarExpand
                          className="h-6 w-6 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                    {isFullScreen && (
                      <button type="button" onClick={closeSidebar}>
                        <XMarkIcon
                          className="h-6 w-6 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </div>
                </Transition.Child>
                <div className="flex justify-center items-center grow flex-col gap-y-5 bg-white dark:bg-zinc-900 text-zinc-900 dark:text-zinc-100 px-6 pb-4">
                  Hello
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <button
        type="button"
        className="flex item-center gap-1 text-sm bg-yellow-300/80 hover:bg-yellow-300 rounded px-4 py-1 text-zinc-900"
        onClick={() => setSidebarOpen(true)}
      >
        Notes & API
      </button>
    </div>
  );
}

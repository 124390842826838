import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import Activity from "./TaskActivity";
import Documents from "./TaskDocuments";
import TaskSidebar from "./TaskSidebar";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import EditableHeading from "./TaskEditableHeading";

export default function Task({ format }) {
  const { id } = useParams();

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [sidebarOpen, setSidebarOpen] = useState(viewportWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      const newViewportWidth = window.innerWidth;
      setViewportWidth(newViewportWidth);

      if (newViewportWidth < 1024) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setSidebarOpen]);

  const { data: tasks, isLoading: tasksLoading } = useQuery(["task"], () => {
    return Axios.get("https://insurtechies.dev/api/task/task").then(
      (res) => res.data
    );
  });

  if (tasksLoading) {
    return (
      <h1 className="bg-white dark:bg-zinc-800 dark:text-white">Loading...</h1>
    );
  }

  // CHANGE TO ID

  const task = tasks.find(
    (task) => format(task.Id.toLowerCase()) === format(id.toLowerCase())
  );

  function getFormattedDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
  }

  const formattedDate = getFormattedDate();

  return (
    <div
      className={
        (sidebarOpen ? "mr-20 lg:mr-72" : "mr-20") +
        " flex justify-between min-h-[calc(100vh-64px)] text-zinc-900 dark:text-zinc-200"
      }
    >
      <div className="flex justify-center w-full py-10 px-5 bg-white dark:bg-zinc-800">
        <div className="flex flex-col gap-5 max-w-screen-md w-full">
          <div className="flex relative">
            <div
              key={task?.Id}
              className="flex flex-col justify-center gap-5 w-full"
            >
              <div className="flex flex-col justify-center gap-2">
                <div className="flex justify-between gap-5 w-full">
                  <h2 className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100 w-full">
                    <EditableHeading text={task?.Title} />
                  </h2>
                  <div className="flex gap-2 text-sm">
                    <button className="bg-[#008000] px-2 py-0.5 rounded h-fit text-zinc-100">
                      Complete
                    </button>
                    <button className="flex items-center h-fit">
                      <EllipsisVerticalIcon className="h-6 w-6 shrink-0" />
                    </button>
                  </div>
                </div>
                <div className="flex flex-wrap items-center gap-2 text-sm">
                  <div
                    className="flex items-center gap-1 px-2 py-0.5 rounded-full text-zinc-100"
                    style={{ backgroundColor: "green" }}
                  >
                    <p>{task?.Status}</p>
                  </div>
                  <p className="text-zinc-700 dark:text-zinc-300">
                    {task?.board} created {task?.created_date ?? formattedDate}{" "}
                    by{" "}
                    <strong className="cursor-pointer">
                      {task?.created_by ?? "Alex Van Vleet"}
                    </strong>
                  </p>
                </div>
              </div>
              <p className="text-zinc-700 dark:text-zinc-300 text-sm leading-6">
                {task?.Description ?? "No description"}
              </p>
            </div>
          </div>
          <Documents />
          <Activity />
        </div>
      </div>
      <TaskSidebar
        viewportWidth={viewportWidth}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </div>
  );
}

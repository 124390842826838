export default function Appearance() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 divide-y divide-zinc-200 dark:divide-zinc-900 space-y-4 pb-10">
      <div className="pt-4">
        <div className="flex flex-col gap-1">
          <h2 className="text-2xl font-semibold">Navigation Theme</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            Choose the navigation theme.
          </p>
        </div>
        <div className="mt-4">
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-5">
            {[
              { color: "bg-indigo-800", label: "Indigo" },
              { color: "bg-indigo-400", label: "Light Indigo" },
              { color: "bg-blue-800", label: "Blue" },
              { color: "bg-blue-400", label: "Light Blue" },
              { color: "bg-green-800", label: "Green" },
              { color: "bg-green-400", label: "Light Green" },
              { color: "bg-red-800", label: "Red" },
              { color: "bg-red-500", label: "Light Red" },
              { color: "bg-zinc-800", label: "Gray" },
              { color: "bg-zinc-200", label: "Neutral", defaultChecked: true },
            ].map(({ color, label, defaultChecked }) => (
              <label
                key={label}
                className="flex flex-col items-center space-y-2"
              >
                <div
                  className={`${color} w-full h-12 rounded peer-checked:border-blue-500 peer-checked:ring peer-checked:ring-blue-200`}
                />
                <div className="flex items-center gap-1 w-full">
                  <input
                    type="radio"
                    name="nav-theme"
                    defaultChecked={defaultChecked}
                  />
                  <span className="my-auto text-sm">{label}</span>
                </div>
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="pt-4">
        <div className="flex flex-col gap-1">
          <h2 className="text-2xl font-semibold">Column Width</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            Choose your preferred column width.
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-1 text-sm">
            <input type="radio" id="narrow" name="width" />
            <label
              htmlFor="narrow"
              className="my-auto ml-2 text-zinc-700 dark:text-zinc-300"
            >
              Narrow
            </label>
          </div>
          <div className="flex items-center gap-1 text-sm">
            <input type="radio" id="medium" name="width" />
            <label
              htmlFor="medium"
              className="my-auto ml-2 text-zinc-700 dark:text-zinc-300"
            >
              Medium
            </label>
          </div>
          <div className="flex items-center gap-1 text-sm">
            <input type="radio" id="wide" name="width" defaultChecked={true} />
            <label
              htmlFor="wide"
              className="my-auto ml-2 text-zinc-700 dark:text-zinc-300"
            >
              Wide
            </label>
          </div>
        </div>
      </div>

      <div className="pt-4">
        <div className="flex flex-col gap-1">
          <h2 className="text-2xl font-semibold">Behavior</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            Customize the behavior of the system layout and default views
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-1">
          <h3 className="font-semibold">Layout Width</h3>
          <div className="flex items-center gap-1 text-sm">
            <input
              type="radio"
              id="fixed"
              name="layout-width"
              defaultChecked={true}
            />
            <label
              htmlFor="fixed"
              className="flex flex-col my-auto ml-2 text-zinc-700 dark:text-zinc-300"
            >
              <span>Fixed</span>
              <span className="text-sm text-zinc-500 dark:text-zinc-400">
                Content will be a maximum of 1280 pixels wide.
              </span>
            </label>
          </div>
          <div className="flex items-center gap-1 text-sm">
            <input type="radio" id="fluid" name="layout-width" />
            <label
              htmlFor="fluid"
              className="flex flex-col my-auto ml-2 text-zinc-700 dark:text-zinc-300"
            >
              <span>Fluid</span>
              <span className="text-sm text-zinc-500 dark:text-zinc-400">
                Content will span 100% of the page width.
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

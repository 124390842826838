import { useState } from "react";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function EditableHeading({ text }) {
  const [isHover, setIsHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);

  const handleHoverEnter = () => {
    setIsHover(true);
  };

  const handleHoverLeave = () => {
    setIsHover(false);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    setEditedText(e.target.value);
  };

  return (
    <div>
      <div>
        <div
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverLeave}
          className="flex items-center gap-2"
        >
          {isEditing ? (
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={editedText}
                onChange={handleInputChange}
                className="bg-transparent"
              />
              <button
                onClick={handleSaveClick}
                className="text-sm bg-[#008000] font-normal text-white px-2 py-0.5 rounded"
              >
                Save
              </button>
            </div>
          ) : (
            <h1>{editedText}</h1>
          )}
          {isHover && (
            <span
              className={`edit-icon ${isHover ? "cursor-pointer" : "hidden"}`}
              onClick={handleEditClick}
            >
              {isEditing ? (
                <XMarkIcon className="w-5 h-5" />
              ) : (
                <PencilIcon className="w-5 h-5" />
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export default function DesktopStepper({ steps, activeStep, setActiveStep }) {
  return (
    <Stepper activeStep={activeStep} className="px-4 sm:px-6 lg:px-8 py-8">
      {steps.map((step, index) => (
        <Step key={step.id}>
          <Link
            to={`/quote/${step.href}`}
            className="flex items-center gap-1"
            onClick={() => setActiveStep(index)}
          >
            <StepLabel>
              <span className="text-sm text-zinc-900 dark:text-zinc-100">
                {step.name}
              </span>
            </StepLabel>
          </Link>
        </Step>
      ))}
    </Stepper>
  );
}

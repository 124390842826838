import { useState } from "react";
import Assignees from "./TaskAssignees";
import LinkedTasks from "./LinkedTasks";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ClockIcon,
  DocumentChartBarIcon,
  LinkIcon,
  TagIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { name: "Assignees", href: "#", icon: UserIcon, current: true },
  { name: "Labels", href: "#", icon: TagIcon, current: false },
  { name: "Due Date", href: "#", icon: ClockIcon, current: false },
  { name: "Linked Tasks", href: "#", icon: LinkIcon, current: false },
  { name: "Participants", href: "#", icon: UserGroupIcon, current: false },
  {
    name: "Linked Quote",
    href: "#",
    icon: DocumentChartBarIcon,
    current: false,
  },
];

export default function TaskSidebar({ sidebarOpen, setSidebarOpen }) {
  const [assigneesEdit, setAssigneesEdit] = useState(false);
  const [assignedPerson, setAssignedPerson] = useState("");
  const [linkedTasksEdit, setLinkedTasksEdit] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // CHANGE SKINNY SIDEBAR COLOR

  return (
    <>
      {sidebarOpen ? (
        <div
          className={
            sidebarOpen
              ? "fixed right-0 w-72 z-50 p-5 bg-white dark:bg-zinc-900 min-h-[calc(100vh-64px)] shadow"
              : "hidden"
          }
        >
          <div className="flex justify-end w-full">
            <button
              onClick={toggleSidebar}
              className="bg-zinc-200 dark:bg-zinc-700 hover:bg-zinc-300 dark:hover:bg-zinc-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
            >
              {sidebarOpen ? (
                <ChevronDoubleRightIcon className="h-4 w-4" />
              ) : (
                <ChevronDoubleLeftIcon className="h-4 w-4" />
              )}
            </button>
          </div>
          <ul className="divide-y divide-gray-200 text-sm">
            <li className="flex flex-col justify-center gap-2 py-4 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Assignees
                </h5>
                <button
                  onClick={() => setAssigneesEdit(!assigneesEdit)}
                  className="text-zinc-600 dark:text-zinc-400"
                >
                  edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-500">
                  {assignedPerson ? assignedPerson : "None"}
                </p>
                <button className="underline text-zinc-600 dark:text-zinc-400">
                  Assign Self
                </button>
              </div>
            </li>

            {assigneesEdit ? (
              <Assignees
                assignedPerson={assignedPerson}
                setAssignedPerson={setAssignedPerson}
              />
            ) : (
              ""
            )}

            <li className="flex flex-col justify-center gap-2 py-2 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Labels
                </h5>
                <button className="text-zinc-600 dark:text-zinc-400">
                  edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-500">None</p>
              </div>
            </li>

            <li className="flex flex-col justify-center gap-2 py-2 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Due Date
                </h5>
                <button className="text-zinc-600 dark:text-zinc-400">
                  edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-500">None</p>
                <button className="underline text-zinc-600 dark:text-zinc-400">
                  Due Today
                </button>
              </div>
            </li>

            <li className="flex flex-col justify-center gap-2 py-2 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Linked Tasks
                </h5>
                <button
                  onClick={() => setLinkedTasksEdit(!linkedTasksEdit)}
                  className="text-zinc-600 dark:text-zinc-400"
                >
                  edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-500">None</p>
              </div>
            </li>

            {linkedTasksEdit ? <LinkedTasks /> : ""}

            <li className="flex flex-col justify-center gap-2 py-2 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Participants
                </h5>
                <button className="text-zinc-600 dark:text-zinc-400">
                  edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-500">None</p>
              </div>
            </li>

            <li className="flex flex-col justify-center gap-2 py-2 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Linked Quote
                </h5>
                <button className="text-zinc-600 dark:text-zinc-400">
                  edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-500">None</p>
              </div>
            </li>
          </ul>
        </div>
      ) : (
        <div
          className={
            sidebarOpen
              ? "hidden"
              : "fixed right-0 z-50 block w-20 h-[calc(100vh-64px)] overflow-y-auto bg-white dark:bg-zinc-800 p-5 shadow"
          }
        >
          <nav className="flex flex-col items-center">
            <div className="flex justify-center items-center w-full">
              <button
                onClick={toggleSidebar}
                className="bg-zinc-200 dark:bg-zinc-700 hover:bg-zinc-300 dark:hover:bg-zinc-600 group flex gap-x-3 rounded-md p-2 mb-1 text-sm leading-6 font-semibold"
              >
                {sidebarOpen ? (
                  <ChevronDoubleRightIcon className="h-4 w-4" />
                ) : (
                  <ChevronDoubleLeftIcon className="h-4 w-4" />
                )}
              </button>
            </div>
            <ul className="flex flex-col items-center space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-zinc-700 hover:text-zinc-900 hover:bg-zinc-300 dark:text-zinc-400 dark:hover:text-white dark:hover:bg-zinc-800 group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
                  >
                    <item.icon
                      className="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{item.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}

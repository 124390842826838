import React, { useRef, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import { MultipleContainers } from "./MultipleContainersComponent";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import Loader from "../../../../Loader";

export default function PageDesign() {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const containersRef = useRef([]);
  const itemsRef = useRef({});

  const [containerData, setContainerData] = useState({});
  const [lineOfBusiness, setLineOfBusiness] = useState([]);
  const [selectedLob, setSelectedLob] = useState(null);
  const [inputFieldsData, setInputFieldsData] = useState([]);
  const [originalInputFields, setOriginalInputFields] = useState([]);
  const [items, setItems] = useState({ E: [] });

  const fetchInputFields = async () => {
    const url = `https://insurtechies.dev/api/policy/inputfield?count=true&$filter=programId eq ${id} and isDeleted eq false`;

    try {
      const token = await getAccessTokenSilently();
      const response = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        const filteredData = response.data
          .filter((item) => !item.isDeleted)
          .map((item, index) => ({
            ...item,
            name: item.name || `Input Field ${index + 1}`,
          }));
        setInputFieldsData(filteredData);
        setOriginalInputFields(filteredData);
        setItems({ E: filteredData });
        return filteredData;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching input fields:", error);
      }
      return [];
    }
  };

  const fetchLineOfBusiness = async () => {
    const url = `https://insurtechies.dev/api/policy/lineofbusiness?count=true&$filter=programId eq ${id} and isDeleted eq false`;

    try {
      const token = await getAccessTokenSilently();
      const response = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        return response.data;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      console.error("Error fetching line of business:", error);
      return [];
    }
  };

  const fetchPageDesign = useCallback(async () => {
    if (!selectedLob) return null;

    const lookupKey = selectedLob.id ? selectedLob.id : selectedLob.name;
    const url = `https://insurtechies.dev/api/policy/pageDesign?$filter=lookupKey eq '${lookupKey}'`;

    try {
      const token = await getAccessTokenSilently();
      const response = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.length > 0) {
        const pageDesigns = response.data;
        const mostRecentEntry = pageDesigns[pageDesigns.length - 1];
        const parsedData = JSON.parse(mostRecentEntry.data);
        return parsedData;
      } else {
        console.error("No data returned from the API or data is empty");
        return null;
      }
    } catch (error) {
      console.error("Error fetching page design:", error);
      return null;
    }
  }, [getAccessTokenSilently, selectedLob]);

  const { data: inputFields, isLoading: inputFieldsLoading } = useQuery(
    ["inputField", id],
    fetchInputFields,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: lobData, isLoading: lobLoading } = useQuery(
    ["lineOfBusiness", id],
    fetchLineOfBusiness,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (lobData) {
      setLineOfBusiness(lobData);
    }
  }, [lobData]);

  useEffect(() => {
    const initializeContainerData = async () => {
      if (selectedLob) {
        const mostRecentPageDesign = await fetchPageDesign();
        if (mostRecentPageDesign) {
          const { display, inputFields: inputFieldsMap } = mostRecentPageDesign;

          const crossReferencedData = display.map((container, index) => ({
            container: container.title || `Container ${index + 1}`,
            fields: container.fields.map((fieldId) => ({
              id: fieldId,
              name: inputFieldsMap[fieldId]?.name || `Field ${fieldId}`,
              columnWidth: inputFieldsMap[fieldId]?.columnWidth || 100,
              requiredToQuote:
                inputFieldsMap[fieldId]?.required?.quote || false,
              ...inputFieldsMap[fieldId],
            })),
          }));

          console.log("Cross-referenced container data:", crossReferencedData);

          const newContainerData = crossReferencedData.reduce(
            (acc, container, index) => {
              const containerId = `Container${index + 1}`;
              acc[containerId] = {
                id: containerId,
                title: container.container,
                collapsible: container.collapsible || false,
                type: container.type || "default",
                columnWidth: container.columnWidth || 100,
              };
              return acc;
            },
            {}
          );
          console.log("New Container Data:", newContainerData);
          setContainerData(newContainerData);

          const newItems = crossReferencedData.reduce(
            (acc, container, index) => {
              const containerId = `Container${index + 1}`;
              acc[containerId] = container.fields;
              return acc;
            },
            { E: inputFieldsData }
          );
          console.log("New Items:", newItems);

          const usedFieldIds = crossReferencedData.flatMap((container) =>
            container.fields.map((field) => field.id)
          );
          const availableInputFields = inputFieldsData.filter(
            (field) => !usedFieldIds.includes(field.id)
          );

          setItems({ ...newItems, E: availableInputFields });
          containersRef.current = Object.keys(newContainerData);
          itemsRef.current = { ...newItems, E: availableInputFields };
        } else {
          console.log("No page design data found for selected LOB");
          setContainerData({});
          setItems({ E: inputFieldsData });
        }
      }
    };

    initializeContainerData();
  }, [selectedLob, inputFieldsData, fetchPageDesign]);

  const handleSave = async (containers, items) => {
    if (!Array.isArray(containers) || typeof items !== "object") {
      console.error("Invalid containers or items structure");
      return;
    }

    if (!selectedLob) {
      console.error("No line of business selected");
      return;
    }

    const usedItems = containers.reduce((acc, containerId) => {
      if (items[containerId]) {
        acc.push(...items[containerId]);
      }
      return acc;
    }, []);

    const defaultItemFields = (item) => ({
      name: item.name || "",
      order: item.order || 1,
      width: item.columnWidth || 100,
      placeholderText: item.placeholderText || "",
      tooltip: item.tooltip || "",
      displayFormat: item.displayFormat || "",
      required: item.required || {
        quote: true,
        bind: false,
      },
      disabled: item.disabled || false,
      attachToExposure: item.attachToExposure || false,
      canOverride: item.canOverride || false,
    });

    const payload = {
      name: `PageDesign-${id}`,
      lookupKey: selectedLob.id ? selectedLob.id : selectedLob.name,
      programId: id,
      data: JSON.stringify({
        display: containers.map((containerId, index) => ({
          type: containerData[containerId]?.type || "default",
          title: containerData[containerId]?.title || "",
          fields: items[containerId].map((item) => item.id),
          isCollapsible: containerData[containerId]?.collapsible || false,
          columnWidth: containerData[containerId]?.columnWidth || 100,
          order: index + 1,
        })),
        inputFields: usedItems.reduce((acc, item, index) => {
          acc[item.id] = {
            ...defaultItemFields(item),
            order: index + 1,
          };
          return acc;
        }, {}),
      }),
    };

    console.log(JSON.stringify(payload, null, 2));

    try {
      const token = await getAccessTokenSilently();
      const url = "https://insurtechies.dev/api/policy/pageDesign";

      const response = await Axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Save successful:", response.data);
      toast.success("Save successful!");
    } catch (error) {
      if (error.response) {
        console.error("Error saving page design:", error.response.data);
      } else if (error.request) {
        console.error("Error saving page design:", error.request);
      } else {
        console.error("Error saving page design:", error.message);
      }
      toast.error("Error saving page design.");
    }
  };

  const handleUpdateContainerData = (id, data) => {
    setContainerData((prevData) => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        ...data,
      },
    }));
  };

  const handleSaveItem = (containerId, updatedItem) => {
    setItems((prevItems) => {
      const updatedContainerItems = prevItems[containerId].map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      );

      return {
        ...prevItems,
        [containerId]: updatedContainerItems,
      };
    });
  };

  const handleRemoveItem = (containerId, itemId) => {
    setItems((items) => {
      const removedItem = items[containerId].find((item) => item.id === itemId);
      return {
        ...items,
        [containerId]: items[containerId].filter((item) => item.id !== itemId),
        E: [resetItemToOriginal(removedItem), ...items["E"]],
      };
    });
  };

  const resetItemToOriginal = (item) => {
    const originalItem = originalInputFields.find(
      (original) => original.id === item.id
    );
    return originalItem ? { ...originalItem } : item;
  };

  const handleAddInputToContainer = (containerId, input) => {
    setItems((prevItems) => {
      const updatedItems = { ...prevItems };
      updatedItems[containerId] = [...updatedItems[containerId], input];
      updatedItems["E"] = updatedItems["E"].filter(
        (item) => item.id !== input.id
      );
      return updatedItems;
    });
  };

  const getNextContainerId = () => {
    const containerIds = Object.keys(containerData);
    const numericIds = containerIds
      .map((id) => parseInt(id.replace("Container", ""), 10))
      .filter((num) => !isNaN(num));
    const nextId = Math.max(0, ...numericIds) + 1;
    return `Container${nextId}`;
  };

  const handleRemoveContainer = (containerID) => {
    setContainerData((containers) => {
      const newContainers = { ...containers };
      delete newContainers[containerID];
      return newContainers;
    });

    setItems((items) => {
      const containerItems = items[containerID] || [];
      return {
        ...items,
        E: [
          ...containerItems.map((item) => resetItemToOriginal(item)),
          ...items["E"],
        ],
        [containerID]: [],
      };
    });
  };

  if (inputFieldsLoading || lobLoading) return <Loader />;

  console.log("Rendered Container Data:", containerData);
  console.log("Rendered Items:", items);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5">
      <div className="flex max-md:flex-col justify-between items-center gap-x-5 gap-y-2.5 mb-5">
        <div className="w-full">
          <h2 className="text-zinc-900 dark:text-zinc-100 text-xl font-semibold">
            Page Designer
          </h2>
          <span className="text-sm text-zinc-500 dark:text-zinc-400 font-light">
            Control the page layout, sorting, input field display styles, and
            more.
          </span>
        </div>
        <div className="flex justify-between md:justify-end items-center gap-x-1 w-full">
          <div className="w-full max-w-xs">
            <select
              defaultValue=""
              className="block h-9 p-1.5 w-full rounded-md dark:bg-zinc-900 dark:ring-1 dark:ring-zinc-800 dark:text-zinc-100 border-0 text-zinc-900 shadow-sm ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 max-w-xs text-sm leading-6 cursor-pointer"
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue === "Insured" || selectedValue === "Policy") {
                  setSelectedLob({ name: selectedValue });
                } else {
                  const selected = lineOfBusiness.find(
                    (lob) => lob.id === selectedValue
                  );
                  setSelectedLob(selected);
                }
              }}
            >
              <option value="" disabled>
                Select a page
              </option>
              <option value="Insured">Insured</option>
              {lineOfBusiness.map((lob) => (
                <option key={lob.id} value={lob.id}>
                  {lob.name}
                </option>
              ))}
              <option value="Policy">Policy</option>
            </select>
          </div>
          <div className="flex gap-x-1">
            <button className="inline-flex items-center gap-x-1 rounded-md bg-zinc-800 px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-700 focus:z-10 whitespace-nowrap">
              Preview
            </button>
            <button
              className="inline-flex items-center gap-x-1 rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:z-10 whitespace-nowrap"
              onClick={() =>
                handleSave(containersRef.current, itemsRef.current)
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <MultipleContainers
        columns={3}
        itemCount={5}
        strategy={rectSortingStrategy}
        wrapperStyle={() => ({
          width: "auto",
          height: "auto",
        })}
        vertical
        inputFields={inputFields}
        items={items}
        setItems={setItems}
        onSave={(containers, items) => {
          containersRef.current = containers;
          itemsRef.current = items;
        }}
        setContainerData={setContainerData}
        containerData={containerData}
        onUpdateContainerData={handleUpdateContainerData}
        handleSaveItem={handleSaveItem}
        handleRemoveItem={handleRemoveItem}
        handleAddInputToContainer={handleAddInputToContainer}
        getNextContainerId={getNextContainerId}
        handleRemoveContainer={handleRemoveContainer}
      />
    </div>
  );
}

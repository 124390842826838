export default function LocationEdit({
  location,
  setEditingRow,
  setEditedLocation,
  editedLocation,
  handleSaveChanges,
}) {
  const { location: loc, building, addressData } = location;
  const { addressOne, city, state, zip, county } = addressData;

  const fields = [
    { name: "locationNumber", label: "Location #", defaultValue: loc },
    { name: "buildingNumber", label: "Building #", defaultValue: building },
    { name: "address", label: "Address", defaultValue: addressOne },
    { name: "city", label: "City", defaultValue: city },
    { name: "state", label: "State", defaultValue: state ?? "N/A" },
    { name: "zip", label: "ZIP", defaultValue: zip },
    { name: "county", label: "County", defaultValue: county },
  ];

  const handleChange = (name, value) => {
    setEditedLocation({
      ...editedLocation,
      [name]: value,
    });
  };

  const inputFields = fields.map((field) => (
    <div key={field.name} className="sm:col-span-2">
      <label
        htmlFor={field.name}
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
      >
        {field.label}
      </label>
      <div className="mt-2">
        <input
          type="text"
          defaultValue={field.defaultValue}
          name={field.name}
          id={field.name}
          onChange={(e) => handleChange(field.name, e.target.value)}
          className="block w-full rounded-md dark:bg-zinc-800 dark:ring-zinc-800 dark:text-zinc-100 border-0 py-1.5 text-zinc-900 shadow-sm ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  ));

  return (
    <tr className="bg-zinc-100 dark:bg-zinc-900">
      <td colSpan="100%">
        <form className="w-full p-4" onSubmit={handleSaveChanges}>
          <div className="space-y-4">
            <div className="border-b border-zinc-900/10 dark:border-zinc-100/10 pb-6">
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-6">
                {inputFields}
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={() => setEditingRow(false)}
              className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-[#4d7c0f]/90 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      </td>
    </tr>
  );
}

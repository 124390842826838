import {useState} from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Dialog } from '@headlessui/react'
import Pagination from "../Pagination";
import SearchSort from "../SearchSort";
import 'react18-json-view/src/style.css'
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

class PagingData {
  currentPage = 1;
  itemsPerPage = 10;
}

function BuildSearchUrl(pagingData, searchTerm) {
  pagingData ??= new PagingData();
  searchTerm ??= '';
  let url = [];
  if(searchTerm !== '') {
    const term = ['key', 'value'].map(t => `(contains(${t},'${searchTerm}'))`).join('or');
    url.push(`$filter=${term}`);
  }
  url.push('$count=true');
  url.push('$orderby=id desc');
  url.push(`$top=${pagingData.itemsPerPage}`);
  if(pagingData.currentPage > 1) {
    url.push(`$skip=${(pagingData.currentPage-1) * pagingData.itemsPerPage}`);
  }
  return url.join('&');
}

async function fetchFuzzyConfigs(params) {
  let url = 'administration/autoclearance/fuzzy';
  if(!!params)
    url += '?' + params;
	return await axios.get(url);
}


export default function FuzzyConfiguration() {
  const [searchTerm, setSearchTerm] = useState('');
  const [paging, setPaging] = useState(new PagingData());
  const url = BuildSearchUrl(paging, searchTerm);
  const {data, isLoading, error } = useQuery({queryKey: ["fuzzyConfig", url], queryFn: () => fetchFuzzyConfigs(url), staleTime: 10*1000});
  const totalItems = data?.headers?.count ?? 0;
  const items = data?.data ?? [];
  items.sort((a, b) => a.id - b.id);

  //if (isLoading)
    //return (<div>loading...</div>);
  if (error || !items || items.length == undefined)
    return (<div>error</div>);
  let sortOptions = {};
  let handleSortChange = () => {};

  return (
    <>
      <header className="relative isolate px-4 py-10 sm:px-6 lg:px-8 mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
            Fuzzy Configuration
          </h2>
          <p className="leading-8 text-zinc-600 dark:text-zinc-300">
            Configure the model to model matching algorithm
          </p>
        </div>
      </header>

      <div className="bg-zinc-50 dark:bg-zinc-900 ring-1 ring-zinc-900/5 dark:ring-zinc-800 px-4 py-8">
        <div className="flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <SearchSort
                isSearching={isLoading}
                isSorting={false}
                sortOptions={sortOptions}
                setSearchTerm={setSearchTerm}
                handleSortChange={handleSortChange}
                showSortOptions={false}
                setShowSortOptions={() => {}}
              />
              <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-700">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                      <span className="cursor-pointer">Edit</span>
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                      <span className="cursor-pointer">Key</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Search Term</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Allow/Block</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Use</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Match %</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Full / Partial</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                  {items.map((data) => (
                    <tr key={data.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                        <button onMouseDown={() => {}}><MagnifyingGlassIcon className="h-4 w-4" /></button>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                        {data.key}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.value}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.isBlockList ? "block" : "allow"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.usedFor ?? "json root"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.requiredRatio*100}%
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.partialMatch ? "partial" : "full"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalPages={Math.ceil(totalItems / paging.itemsPerPage)}
                currentPage={paging.currentPage}
                onPageChange={(p) => setPaging({...paging, currentPage:p})}
                itemsPerPage={paging.itemsPerPage}
                onItemsPerPageChange={(i) => setPaging({...paging, itemsPerPage: parseInt(i.target.value)})}
                totalItems={totalItems}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

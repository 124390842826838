import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ClipboardDocumentCheckIcon,
  HashtagIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import Insured from "../steps/insured/Insured";
import Location from "../steps/location/Location";
import LOB from "../steps/lob/LOB";
import Policy from "../steps/policy/Policy";
import Carrier from "../steps/contract/Contract";
import Review from "../steps/review/Review";
import MobileStepper from "./MobileStepper";
import DesktopStepper from "./DesktopStepper";
import StepperButtons from "./StepperButtons";

const steps = [
  {
    id: "1",
    name: "Insured",
    Icon: UserIcon,
    href: "insured",
    Component: Insured,
  },
  {
    id: "2",
    name: "Location",
    Icon: HomeIcon,
    href: "location",
    Component: Location,
  },
  {
    id: "3",
    name: "LOB #N",
    Icon: HashtagIcon,
    href: "lob",
    Component: LOB,
  },
  {
    id: "5",
    name: "Policy",
    Icon: ClipboardDocumentCheckIcon,
    href: "policy",
    Component: Policy,
  },
  {
    id: "6",
    name: "Carrier",
    Icon: ShieldCheckIcon,
    href: "carrier",
    Component: Carrier,
  },
  {
    id: "7",
    name: "Review",
    Icon: MagnifyingGlassIcon,
    href: "review",
    Component: Review,
  },
];

export default function StepperComponent({ accessToken }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { stepId } = useParams();
  const navigate = useNavigate();

  const currentStepIndex = steps.findIndex((step) => step.href === stepId) || 0;
  const activeStepHref = steps[activeStep]?.href;

  React.useEffect(() => {
    if (stepId !== activeStepHref) {
      setActiveStep(currentStepIndex);
    }
  }, [stepId, currentStepIndex, activeStepHref]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    const nextStep = activeStep + 1;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (nextStep < steps.length) {
      navigate(`/quote/${steps[nextStep].href}`);
    }
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      navigate(`/quote/${steps[activeStep - 1].href}`);
    }
  };

  const updatedSteps = steps.map((step, index) => {
    if (index < currentStepIndex) {
      return { ...step, status: "complete" };
    } else if (index === currentStepIndex) {
      return { ...step, status: "current" };
    } else {
      return { ...step, status: "upcoming" };
    }
  });

  return (
    <div className="w-full">
      <div className="md:hidden">
        <MobileStepper
          steps={steps}
          updatedSteps={updatedSteps}
          currentStepIndex={currentStepIndex}
        />
      </div>
      <div className="max-md:hidden">
        <DesktopStepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </div>
      <div>
        {steps[activeStep]?.Component &&
          React.createElement(steps[activeStep].Component, {
            accessToken,
            handleBack,
          })}
      </div>
      <StepperButtons
        steps={steps}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </div>
  );
}

import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function SelectDropdown({ label, listData }) {
  return (
    <div className="col-span-2">
      <label
        htmlFor="select-dropdown"
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
      >
        {label}
      </label>
      <Menu as="div" className="relative inline-block text-left mt-1 w-full">
        <div>
          <Menu.Button className="inline-flex h-8 my-auto w-full justify-between gap-x-1.5 rounded-md bg-white dark:bg-zinc-800 px-3 py-2 text-sm text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-500 dark:placeholder:text-zinc-400">
            Select an option
            <ChevronDownIcon
              aria-hidden="true"
              className="-mr-1 h-4 w-4 text-zinc-400 dark:text-zinc-400"
            />
          </Menu.Button>
        </div>
        <Menu.Items className="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {Array.isArray(listData) &&
              listData.map((option) => (
                <Menu.Item key={option.id}>
                  <span className="block px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:bg-zinc-100 hover:text-zinc-900 dark:hover:bg-zinc-700 dark:hover:text-zinc-100 cursor-pointer">
                    {option.value}
                  </span>
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}

import {
  ArrowDownOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import NotesApis from "../NotesApis";

export default function StepperButtons({
  steps,
  activeStep,
  handleNext,
  handleBack,
}) {
  return (
    <div>
      {activeStep < steps.length - 1 && (
        <div className="flex flex-row px-4 sm:px-6 lg:px-8 pt-5">
          <button
            className="flex item-center gap-1 bg-white/80 hover:bg-white border border-zinc-400 dark:border-none rounded px-4 py-1 text-zinc-900 text-sm"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            <ChevronLeftIcon className="my-auto h-4 w-4 text-zinc-900" />{" "}
            Previous
          </button>
          <div className="flex-auto" />
          <div className="flex items-center gap-2">
            <NotesApis />
            <button className="flex item-center gap-1 bg-blue-500/80 hover:bg-blue-500 rounded px-4 py-1 text-white text-sm">
              Save{" "}
              <ArrowDownOnSquareIcon className="my-auto h-4 w-4 text-white" />
            </button>
            <button
              className="flex item-center gap-1 bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] rounded px-4 py-1 text-white text-sm"
              onClick={handleNext}
            >
              Next <ChevronRightIcon className="my-auto h-4 w-4 text-white" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

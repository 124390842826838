import { Fragment } from "react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentDuplicateIcon,
  MapPinIcon,
  MinusIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import Pagination from "../../Pagination";
import LocationEdit from "./LocationEdit";
import Loader from "../../Loader";

export default function LocationsTableView({
  currentPage,
  editedLocation,
  editingRow,
  handleItemsPerPageChange,
  handleChangePage,
  handleCheckboxChange,
  handleSortChange,
  isPaginating,
  isSearching,
  isSorting,
  itemsPerPage,
  locations,
  locationsLoading,
  selectedRows,
  setEditedLocation,
  setEditingRow,
  setShowConfirmDelete,
  sortDirection,
  sortField,
  toggleSelectAll,
  totalItems,
}) {
  return (
    <>
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {locationsLoading || isPaginating || isSearching || isSorting ? (
              <Loader />
            ) : locations?.length > 0 ? (
              <>
                <table className="w-full divide-y divide-zinc-300 dark:divide-zinc-800 border-t border-zinc-300 dark:border-zinc-800">
                  <thead>
                    <tr>
                      <th className="w-8 py-3.5 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wider text-zinc-900 dark:text-zinc-100">
                        <RiCheckboxMultipleLine
                          className="my-auto h-5 w-5 cursor-pointer"
                          onClick={toggleSelectAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap w-16 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                      >
                        <div className="flex gap-1">
                          <MapPinIcon className="h-5 w-5" />
                        </div>
                      </th>
                      <th className="w-20 py-3.5 pl-2 pr-3 text-left text-xs font-medium uppercase tracking-wider text-zinc-900 dark:text-zinc-100">
                        Location
                      </th>
                      <th className="w-20 py-3.5 pl-2 pr-3 text-left text-xs font-medium uppercase tracking-wider text-zinc-900 dark:text-zinc-100">
                        Building
                      </th>
                      {[
                        { name: "Address", field: "address" },
                        { name: "City", field: "city" },
                        { name: "State", field: "state" },
                        { name: "Zip", field: "zip" },
                        { name: "County", field: "county" },
                      ].map(({ name, field }) => (
                        <th
                          key={field}
                          className="w-24 py-3.5 pl-2 pr-3 text-left text-xs font-medium uppercase tracking-wider text-zinc-900 dark:text-zinc-100 cursor-pointer"
                          onClick={() => handleSortChange(field)}
                        >
                          <div className="flex items-center">
                            {name}
                            {sortField === field &&
                              (sortDirection === "asc" ? (
                                <ChevronDownIcon className="ml-2 h-4 w-4" />
                              ) : (
                                <ChevronUpIcon className="ml-2 h-4 w-4" />
                              ))}
                          </div>
                        </th>
                      ))}
                      <th className="w-24 py-3.5 pl-2 pr-3 text-left text-xs font-medium uppercase tracking-wider text-zinc-900 dark:text-zinc-100">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                    {locations.map((location, index) => (
                      <Fragment key={location.id}>
                        <tr className="even:bg-zinc-50 dark:even:bg-zinc-900 hover:bg-zinc-100 dark:hover:bg-zinc-800 group">
                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-zinc-500 dark:text-zinc-400 relative">
                            <input
                              type="checkbox"
                              id={`checkbox-${location.id}`}
                              className="opacity-0 absolute h-full w-full cursor-pointer"
                              checked={selectedRows.includes(location.id)}
                              onChange={() => handleCheckboxChange(location.id)}
                            />
                            <label
                              htmlFor={`checkbox-${location.id}`}
                              className="block h-4 w-4 rounded border border-zinc-300 bg-white dark:border-none dark:bg-zinc-700 cursor-pointer"
                            >
                              <svg
                                className={`h-full w-full bg-[#4d7c0f] text-white ${
                                  selectedRows.includes(location.id)
                                    ? "block"
                                    : "hidden"
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M5 13l4 4L19 7"
                                  stroke="currentColor"
                                  strokeWidth="3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </label>
                          </td>

                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-zinc-500 dark:text-zinc-400">
                            {location.map ? (
                              <CheckIcon className="h-4 w-4" />
                            ) : (
                              <XMarkIcon className="h-4 w-4" />
                            )}
                          </td>
                          {[
                            index + 1,
                            index + 11,
                            location.addressData.addressOne || (
                              <MinusIcon className="h-4 w-auto" />
                            ),
                            location.addressData.city || (
                              <MinusIcon className="h-4 w-auto" />
                            ),
                            location.addressData.state || (
                              <MinusIcon className="h-4 w-auto" />
                            ),
                            location.addressData.zip || (
                              <MinusIcon className="h-4 w-auto" />
                            ),
                            location.addressData.county || (
                              <MinusIcon className="h-4 w-auto" />
                            ),
                          ].map((item, index) => (
                            <td
                              key={index}
                              className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100"
                            >
                              {item}
                            </td>
                          ))}
                          <td className="flex items-center gap-1 opacity-0 group-hover:opacity-100 h-full py-3">
                            <PencilIcon
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Edit"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer"
                              onClick={() =>
                                setEditingRow(
                                  editingRow === location.id
                                    ? null
                                    : location.id
                                )
                              }
                            />
                            <DocumentDuplicateIcon
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Copy"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer"
                            />
                            <TrashIcon
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Delete"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer"
                              onClick={() => {
                                setShowConfirmDelete(true);
                              }}
                            />
                          </td>
                        </tr>
                        {editingRow === location.id && (
                          <LocationEdit
                            location={location}
                            setEditingRow={setEditingRow}
                            setEditedLocation={setEditedLocation}
                            editedLocation={editedLocation}
                            // handleSaveChanges={handleSaveChanges}
                          />
                        )}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalItems / itemsPerPage)}
                  onPageChange={handleChangePage}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  totalItems={totalItems}
                />
              </>
            ) : (
              <div className="text-center py-10">
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  No results found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
